import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetCompaniesQuery } from "../services/api";

const useCompanyOptions = () => {
    const { data: companyData } = useGetCompaniesQuery();
    const { role, assignedCompanies } = useSelector((state) => state.global);

    const companyOptions = useMemo(() => {
        if (!companyData) return [];

        let options = companyData.map(company => ({
            id: company._id,
            name: company.name,
            address: (company.address?.addressLines || []).join(', '),
            city: company.address?.city || 'N/A',
            country: company.address?.country || 'N/A',
            industry: company.industry || 'N/A',
            contactEmail: company.contact?.email || 'N/A',
            contactPhone: company.contact?.phone || 'N/A',
            website: company.website || 'N/A',
            devicesConnected: company.devicesConnected.length,
            sitesConnected: company.sitesConnected.length,
            logicsConnected: company.logicsConnected.length,
            usersConnected: company.usersConnected.length
        }));


        if (role === 'Power Admin') {
            options = options.filter(company => assignedCompanies.includes(company.name));
        }

        return options;
    }, [companyData, role, assignedCompanies]);

    return companyOptions;
};

export default useCompanyOptions;