import React from 'react';
import { HomeOutlined, EdgesensorHighOutlined, Groups3Outlined, GppGoodOutlined, BusinessOutlined, MyLocationOutlined, CompareArrowsOutlined, QueryStatsOutlined, AccessTimeOutlined, TodayOutlined, DateRangeOutlined, CalendarMonthOutlined, CalendarViewMonthOutlined, PictureAsPdfOutlined, PersonOutlineOutlined } from '@mui/icons-material';

const navItems = [
    {
        text: "Homepage",
        icon: <HomeOutlined />,
        role: ["Super Admin", "Power Admin"],
    },
    {
        text: "Management",
        icon: null,
        role: ["Super Admin", "Power Admin"],
    },
    {
        text: "Companies",
        icon: <BusinessOutlined />,
        role: ["Super Admin", "Power Admin"],
        permissions: ['view_companies'],
    },
    {
        text: "Licenses",
        icon: <GppGoodOutlined />,
        role: ["Super Admin", "Power Admin"],
        permissions: ['view_licenses'],
    },
    {
        text: "Admin Management",
        icon: <Groups3Outlined />,
        role: ["Super Admin"],
        permissions: ['view_admin_management'],
    },
    {
        text: "All Users",
        icon: <Groups3Outlined />,
        role: ["Super Admin", "Power Admin"],
        permissions: ['view_all_users'],
    },
    {
        text: "Device Management",
        icon: null,
        role: ["Super Admin", "Power Admin"],
    },
    {
        text: "All Devices",
        icon: <EdgesensorHighOutlined />,
        role: ["Super Admin", "Power Admin"],
        permissions: ['view_all_devices'],
    },
    {
        text: "All Sites",
        icon: <MyLocationOutlined />,
        role: ["Super Admin", "Power Admin"],
        permissions: ['view_all_sites'],
    },
    {
        text: "All Logics",
        icon: <CompareArrowsOutlined />,
        role: ["Super Admin", "Power Admin"],
        permissions: ['view_all_logics'],
    },
    {
        text: "Dashboard",
        icon: <HomeOutlined />,
        role: ["Admin", "User", "IT Admin", "Client"],
    },
    {
        text: "My Devices",
        icon: null,
        role: ["Admin", "User", "IT Admin", "Client"],
    },
    {
        text: "Sites",
        icon: <MyLocationOutlined />,
        role: ["Admin", "User", "IT Admin", "Client"],
        permissions: ['view_sites'],
    },
    {
        text: "Devices",
        icon: <EdgesensorHighOutlined />,
        role: ["Admin", "User", "IT Admin", "Client"],
        permissions: ['view_devices'],
    },
    /*{
        text: "Notifications",
        icon: <NotificationsActiveOutlined />,
        role: ["Admin", "User", "IT Admin", "Client"],
    },*/
    {
        text: "Analytics",
        icon: null,
        role: ["Super Admin", "Power Admin", "Admin", "User", "IT Admin", "Client"],
    },
    {
        text: "Overview",
        icon: <QueryStatsOutlined />,
        role: ["Super Admin", "Power Admin", "Admin", "User", "IT Admin", "Client"],
        permissions: ['view_overview'],
    },
    {
        text: "Hourly",
        icon: <AccessTimeOutlined />,
        role: ["Super Admin", "Power Admin", "Admin", "User", "IT Admin", "Client"],
        permissions: ['view_hourly'],
    },
    {
        text: "Daily",
        icon: <TodayOutlined />,
        role: ["Super Admin", "Power Admin", "Admin", "User", "IT Admin", "Client"],
        permissions: ['view_daily'],
    },
    {
        text: "Weekly",
        icon: <DateRangeOutlined />,
        role: ["Super Admin", "Power Admin", "Admin", "User", "IT Admin", "Client"],
        permissions: ['view_weekly'],
    },
    {
        text: "Monthly",
        icon: <CalendarMonthOutlined />,
        role: ["Super Admin", "Power Admin", "Admin", "User", "IT Admin", "Client"],
        permissions: ['view_monthly'],
    },
    {
        text: "Yearly",
        icon: <CalendarViewMonthOutlined />,
        role: ["Super Admin", "Power Admin", "Admin", "User", "IT Admin", "Client"],
        permissions: ['view_yearly'],
    },
    {
        text: "Report",
        icon: <PictureAsPdfOutlined />,
        role: ["Super Admin", "Power Admin", "Admin", "User", "IT Admin", "Client"],
        permissions: ['view_reports'],
    },
    {
        text: "Management",
        icon: null,
        role: ["Admin", "User", "IT Admin", "Client"],
    },
    {
        text: "Users",
        icon: <PersonOutlineOutlined />,
        role: ["Admin", "User", "IT Admin", "Client"],
        permissions: ['view_users'],
    },
    {
        text: "Edit Data",
        icon: <PersonOutlineOutlined />,
        role: ["Admin"],
        permissions: ['view_edit_datas'],
    },
];

export default navItems;
