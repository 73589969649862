import React, { useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import { customColorScheme } from '../components/gradientDefs';

const YearlyLineChart = ({ selectedMac, isSmallScreen, processedData, chartIsLoading, error, showLabels, tabValue }) => {
    const [activeLegends, setActiveLegends] = useState([]);
    const themeMode = useSelector(({ global }) => global.mode);
    const theme = useTheme();

    if (chartIsLoading) {
        return (
            <div style={{ height: '70vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loading />
            </div>
        );
    }

    // Check if data is available
    if (error || error?.status === 404 || !processedData || Object.keys(processedData).length === 0) {
        return <NoDataAvailableMessage />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const filteredData = selectedMac ? (processedData && processedData[selectedMac]) || {} : processedData || {};

    const lastFiveYears = Array.from({ length: 5 }, (_, index) => {
        const currentYear = new Date().getFullYear();
        return (currentYear - index).toString();
    });

    const firstYearData = filteredData[lastFiveYears[0]] || {};
    const keys = firstYearData ? Object.keys(firstYearData) : [];

    const chartData = lastFiveYears.reverse().map(year => {
        const yearData = filteredData[year] || {};
        const yearValues = { year };

        keys.forEach(category => {
            yearValues[category] = yearData[category] || 0;
        });

        return yearValues;
    });

    const lineData = keys.map(category => ({
        id: category,
        data: chartData.map(entry => ({ x: entry.year, y: entry[category] }))
    }));

    // Handle legend click to toggle visibility of data lines
    const handleLegendClick = (legendItem) => {
        setActiveLegends((prevActiveLegends) =>
            prevActiveLegends.includes(legendItem.id)
                ? prevActiveLegends.filter((item) => item !== legendItem.id)
                : [...prevActiveLegends, legendItem.id]
        );
    };

    // Determine which lines to show based on activeLegends
    const filteredLineData = lineData.map(line => ({
        ...line,
        data: line.data.map(point => ({
            ...point,
            y: activeLegends.length === 0 || !activeLegends.includes(line.id) ? point.y : null
        }))
    }));

    return (
        <div style={{
            height: '70vh',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '15px',
            marginTop: '1rem'
        }}>
            <ResponsiveLine
                data={filteredLineData}
                margin={isSmallScreen ? { top: 20, right: 10, bottom: 90, left: 35 } : { top: 30, right: 25, bottom: 100, left: 50 }}
                xScale={{ type: 'point' }}
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: false,
                    reverse: false
                }}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendOffset: 45,
                    legendPosition: 'middle',
                    style: {
                        tick: { fill: textColor },
                        legend: { fill: textColor }
                    },
                    legend: 'Year'
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: isSmallScreen ? -50 : 0,
                    legendOffset: -40,
                    legendPosition: 'middle',
                    style: {
                        tick: { fill: textColor },
                        legend: { fill: textColor }
                    }
                }}
                enableGridX={false}
                enableArea={true}
                areaOpacity={0.1}
                lineWidth={3}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                enablePointLabel={showLabels}
                pointLabel="y"
                pointLabelYOffset={-12}
                enableCrosshair={true}
                useMesh={true}
                legends={isSmallScreen ? [
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: tabValue === 1 ? -15 : 0,
                        translateY: 80,
                        itemsSpacing: tabValue === 1 ? 10 : 2,
                        itemDirection: 'left-to-right',
                        itemWidth: tabValue === 1 ? 80 : 68,
                        itemHeight: 20,
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        symbolShape: 'square',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ],
                        textColor: textColor,
                        itemTextColor: textColor,
                        onClick: handleLegendClick
                    }
                ] : [
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 25,
                        translateY: 85,
                        itemsSpacing: 2,
                        itemDirection: 'left-to-right',
                        itemWidth: 100,
                        itemHeight: 20,
                        itemOpacity: 0.85,
                        symbolSize: 12,
                        symbolShape: 'square',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ],
                        textColor: textColor,
                        itemTextColor: textColor,
                        onClick: handleLegendClick
                    }
                ]}
                colors={customColorScheme}
                theme={{
                    textColor: textColor,
                    axis: {
                        legend: {
                            text: {
                                fill: textColor
                            }
                        },
                        ticks: {
                            text: {
                                fill: textColor
                            }
                        }
                    },
                    dots: {
                        text: {
                            fill: textColor,
                        },
                    },
                }}
                tooltip={({ point }) => (
                    <div style={{ background: '#000', color: '#fff', padding: '10px', fontWeight: 'bold' }}>
                        {point.serieId} : {point.data.y}
                    </div>
                )}
            />
        </div>
    );
};

export default YearlyLineChart;
