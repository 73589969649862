import React from 'react';
import { Box, Button, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DeviceDetailsForm = ({ device, deviceDetails, handleInputChange, handleDeviceSelect, handleUpdateDeviceDetails, filteredDevices }) => {
    const theme = useTheme();

    return (
        <Box
            p={4}
            borderRadius={5}
            sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.secondary.main,
                width: '50%',
                mx: 'auto',
                mt: 4,
            }}
        >
            <Typography variant="h3" sx={{ fontSize: '2rem', fontWeight: 'bold', color: theme.palette.secondary.main, mb: '1rem' }}>
                Device Details
            </Typography>
            <Box mb={2}>
                <Typography>Select Device</Typography>
                <Select
                    fullWidth
                    value={device}
                    onChange={handleDeviceSelect}
                    displayEmpty
                >
                    <MenuItem value="" disabled>
                        Select Device
                    </MenuItem>
                    {filteredDevices.map((device) => (
                        <MenuItem key={device._id} value={device.deviceName}>
                            {device.deviceName}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
            <Box mb={2}>
                <Typography>Device MAC</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={deviceDetails.mac || ''}
                    onChange={(e) => handleInputChange(e, 'mac')}
                    disabled
                />
            </Box>
            <Box mb={2}>
                <Typography>Change Device Name</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={deviceDetails.name || ''}
                    onChange={(e) => handleInputChange(e, 'name')}
                />
            </Box>
            <Box mb={2}>
                <Typography>Entry - Exit</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={deviceDetails.entry || ''}
                    onChange={(e) => handleInputChange(e, 'entry')}
                />
            </Box>
            <Box mb={2}>
                <Typography>Exit - Entry</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={deviceDetails.exit || ''}
                    onChange={(e) => handleInputChange(e, 'exit')}
                />
            </Box>
            <Box mb={2}>
                <Typography>Adult Entry - Exit</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={deviceDetails.adultEntry || ''}
                    onChange={(e) => handleInputChange(e, 'adultEntry')}
                />
            </Box>
            <Box mb={2}>
                <Typography>Child Entry - Exit</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={deviceDetails.childEntry || ''}
                    onChange={(e) => handleInputChange(e, 'childEntry')}
                />
            </Box>
            <Box mb={2}>
                <Typography>Adult Exit - Entry</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={deviceDetails.adultExit || ''}
                    onChange={(e) => handleInputChange(e, 'adultExit')}
                />
            </Box>
            <Box mb={2}>
                <Typography>Adult Exit - Entry</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={deviceDetails.childExit || ''}
                    onChange={(e) => handleInputChange(e, 'childExit')}
                />
            </Box>
            <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                gap={3}
                mt={2}
            >
                <Button
                    onClick={handleUpdateDeviceDetails}
                    variant="contained"
                    sx={{
                        mt: '10px',
                        color: theme.palette.text.black,
                        backgroundColor: theme.palette.primary.main,
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: theme.palette.secondary.dark,
                        },
                        '&:focus': {
                            outline: 'none',
                        }
                    }}
                >
                    Update Device Details
                </Button>
            </Box>
        </Box>
    );
};

export default DeviceDetailsForm;
