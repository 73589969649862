import React, { useState, useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { useSelector } from 'react-redux';
import { getISOWeek } from 'date-fns';
import { customTooltip } from '../components/customTooltip';
import { gradientDefs } from '../components/gradientDefs';
import { useTheme } from '@mui/material';

const WeeklyBarChart = ({ selectedMac, selectedMonth, selectedYear, showLabels, isSmallScreen, processedData, isLoading, error, tabValue }) => {
    const [activeLegends, setActiveLegends] = useState([]);
    const themeMode = useSelector(({ global }) => global.mode);
    const theme = useTheme();

    const chartData = useMemo(() => {
        if (!processedData || isLoading) return [];

        const startDate = new Date(selectedYear, selectedMonth - 1, 1);
        const endDate = new Date(selectedYear, selectedMonth, 0);
        const startISOWeek = getISOWeek(startDate);
        const endISOWeek = getISOWeek(endDate);

        const yearData = processedData[selectedMac]?.[selectedYear];
        if (!yearData) return [];

        const data = [];
        let currentWeek = startISOWeek;

        while (currentWeek <= endISOWeek) {
            const weekData = yearData[selectedMonth]?.[currentWeek];
            if (weekData) {
                const { Total, ...filteredWeekData } = weekData;
                data.push({ week: currentWeek, ...filteredWeekData });
            }
            currentWeek++;
        }

        return data;
    }, [processedData, isLoading, selectedMac, selectedYear, selectedMonth]);

    const allBarNames = useMemo(() => {
        if (chartData.length > 0) {
            return Object.keys(chartData[0]).filter(key => key !== 'week');
        }
        return [];
    }, [chartData]);

    if (isLoading) {
        return <Loading />;
    }

    if (error || error?.status === 404 || !processedData || Object.keys(processedData).length === 0 || !chartData.length) {
        return <NoDataAvailableMessage />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const customLegendItems = allBarNames.map((name, index) => ({
        id: name,
        label: name,
        color: `url(#gradient${index + 1})`,
    }));

    const handleLegendClick = (legendItem) => {
        setActiveLegends((prevActiveLegends) =>
            prevActiveLegends.includes(legendItem.id)
                ? prevActiveLegends.filter((item) => item !== legendItem.id)
                : [...prevActiveLegends, legendItem.id]
        );
    };

    const filteredKeys = allBarNames.filter((name) => !activeLegends.includes(name));

    return (
        <div style={{
            height: '70vh',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '15px'
        }}>
            <ResponsiveBar
                data={chartData}
                keys={filteredKeys}
                indexBy="week"
                margin={isSmallScreen ? { top: 30, right: 10, bottom: 90, left: 30 } : { top: 30, right: 30, bottom: 100, left: 50 }}
                padding={0.25}
                groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Week',
                    legendPosition: 'middle',
                    legendOffset: 45,
                    tickValues: 5,
                    style: { tick: { fill: '#ffffff' }, legend: { fill: '#ffffff' } }
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: 'middle',
                    legendOffset: -40,
                    tickValues: 5,
                    style: { tick: { fill: '#ffffff' }, legend: { fill: '#ffffff' } }
                }}
                enableLabel={showLabels}
                labelSkipWidth={1}
                labelSkipHeight={1}
                labelTextColor={textColor}
                legends={isSmallScreen ? [
                    {
                        data: customLegendItems,
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: tabValue === 1 ? -10 : 10,
                        translateY: 80,
                        itemsSpacing: tabValue === 1 ? 10 : 2,
                        itemWidth: tabValue === 1 ? 80 : 75,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ],
                        textColor: textColor,
                        itemTextColor: textColor,
                        onClick: handleLegendClick
                    }
                ] : [
                    {
                        data: customLegendItems,
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 25,
                        translateY: 85,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ],
                        textColor: textColor,
                        itemTextColor: textColor,
                        onClick: handleLegendClick
                    }
                ]}
                tooltip={customTooltip}
                motionConfig="slow"
                theme={{
                    tooltip: {
                        container: {
                            background: '#000',
                            color: '#fff',
                            fontSize: '14px',
                            borderRadius: '4px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
                            padding: '10px'
                        }
                    },
                    textColor: textColor,
                    axis: {
                        legend: {
                            text: {
                                fill: textColor
                            }
                        },
                        ticks: {
                            text: {
                                fill: textColor
                            }
                        }
                    }
                }}
                defs={gradientDefs}
                fill={allBarNames.map((name, index) => ({
                    match: { id: name },
                    id: `gradient${index + 1}`,
                }))}
            />
        </div>
    );
};

export default WeeklyBarChart;
