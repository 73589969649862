import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSignupMutation } from "../services/api";

const useSignupHook = () => {
    const [inputs, setInputs] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        country: "",
        phoneNumber: "",
    });

    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [signup] = useSignupMutation();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs({ ...inputs, [name]: value });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (inputs.password !== inputs.confirmPassword) {
        setError("Passwords do not match");
        return;
        }

        try {
        const { confirmPassword, ...userData } = inputs;
        const response = await signup(userData);

        if (response.error) {
            setError(response.error.message);
            setSnackbarMessage(response.error.message);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        setSnackbarMessage("Account activated successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => {
            navigate("/login");
        }, 2000);
        } catch (error) {
        setError("Failed to activate account");
        setSnackbarMessage("Failed to activate account");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        }
    };

    return {
        inputs,
        error,
        handleChange,
        handleSubmit,
        snackbarOpen,
        snackbarMessage,
        snackbarSeverity,
        handleSnackbarClose,
    };
};

export default useSignupHook;
