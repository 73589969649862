import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

const CompanyStatBox = ({ title, value }) => {
    // Theme
    const theme = useTheme();
    
    // JSX
    return (
        <Box
            gridColumn="span 3"
            gridRow="span 1"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            flex="1 1 100%"
            backgroundColor={theme.palette.background.paper}
            borderRadius="0.55rem"
        >
            <Typography variant="h5" sx={{ textAlign: 'center', mt: '1.5rem', color: theme.palette.secondary.main, fontWeight: 'bold' }}>
                {title}
            </Typography>
            <Typography variant="h2" fontWeight="600" sx={{ textAlign: 'center', mb: '2.5rem' }}>
                {value}
            </Typography>
        </Box>
    );
};

export default CompanyStatBox;
