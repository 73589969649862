import React from 'react';
import { Box } from '@mui/material';
import CompanyStatBox from '../../components/CompanyStatBox';

const CompanyStats = ({ companyOptions, selectedCompany }) => {
    const selectedCompanyData = companyOptions.find(option => option.name === selectedCompany) || {};

    return (
        <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="160px"
            gap="20px"
            marginTop="1rem"
        >
            <CompanyStatBox
                title="DEVICES CONNECTED"
                value={selectedCompanyData.devicesConnected || 0}
            />
            <CompanyStatBox
                title="ACTIVE SITES"
                value={selectedCompanyData.sitesConnected || 0}
            />
            <CompanyStatBox
                title="ACTIVE LOGICS"
                value={selectedCompanyData.logicsConnected || 0}
            />
            <CompanyStatBox
                title="TOTAL APP USERS"
                value={selectedCompanyData.usersConnected || 0}
            />
        </Box>
    );
};

export default CompanyStats;
