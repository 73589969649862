import React, { useState, useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { useSelector } from 'react-redux';
import { customTooltip } from '../components/customTooltip';
import { gradientDefs } from '../components/gradientDefs';
import { useTheme } from '@mui/material';

const MonthlyBarChart = ({ selectedSensor, selectedYear, showLabels, isSmallScreen, processedData, error, chartIsLoading, tabValue }) => {
    const [activeLegends, setActiveLegends] = useState([]);
    const themeMode = useSelector(({ global }) => global.mode);
    const theme = useTheme();

    const selectedYearValue = useMemo(() => {
        return selectedYear instanceof Date ? selectedYear.getFullYear() : selectedYear.$y || selectedYear;
    }, [selectedYear]);

    const isDataAvailable = useMemo(() => processedData && Object.keys(processedData).length > 0, [processedData]);
    const isSensorDataAvailable = useMemo(() => isDataAvailable && processedData[selectedSensor], [isDataAvailable, processedData, selectedSensor]);
    const isYearDataAvailable = useMemo(() => isSensorDataAvailable && processedData[selectedSensor][selectedYearValue], [isSensorDataAvailable, processedData, selectedSensor, selectedYearValue]);

    const yearData = useMemo(() => isYearDataAvailable ? processedData[selectedSensor][selectedYearValue] : null, [isYearDataAvailable, processedData, selectedSensor, selectedYearValue]);

    const monthNames = useMemo(() => Array.from({ length: 12 }, (_, i) => {
        const month = i + 1;
        return month < 10 ? `0${month}` : `${month}`;
    }), []);

    const uniqueKeys = useMemo(() => {
        const keys = new Set();
        if (yearData) {
            monthNames.forEach(month => {
                if (yearData.hasOwnProperty(month)) {
                    Object.keys(yearData[month]).forEach(key => keys.add(key));
                }
            });
        }
        return keys;
    }, [yearData, monthNames]);

    const chartData = useMemo(() => {
        return monthNames.map(month => {
            const entry = { month };
            if (yearData && yearData.hasOwnProperty(month)) {
                Object.entries(yearData[month]).forEach(([categoryName, value]) => {
                    entry[categoryName] = value;
                });
            } else {
                uniqueKeys.forEach(key => entry[key] = 0);
            }
            return entry;
        });
    }, [yearData, monthNames, uniqueKeys]);

    const keys = useMemo(() => Array.from(uniqueKeys), [uniqueKeys]);

    const textColor = useMemo(() => themeMode === 'light' ? '#000000' : '#FFFFFF', [themeMode]);

    const customLegendItems = useMemo(() => keys.map((name, index) => ({
        id: name,
        label: name,
        color: `url(#gradient${index + 1})`,
    })), [keys]);

    const handleLegendClick = (legendItem) => {
        setActiveLegends((prevActiveLegends) =>
            prevActiveLegends.includes(legendItem.id)
                ? prevActiveLegends.filter((item) => item !== legendItem.id)
                : [...prevActiveLegends, legendItem.id]
        );
    };

    const filteredKeys = useMemo(() => keys.filter((name) => !activeLegends.includes(name)), [keys, activeLegends]);

    if (chartIsLoading) {
        return <Loading />;
    }

    if (error || error?.status === 404 || !yearData) {
        return <NoDataAvailableMessage />;
    }

    return (
        <div style={{
            height: '70vh',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '15px'
        }}>
            <ResponsiveBar
                data={chartData}
                keys={filteredKeys}
                indexBy="month"
                margin={isSmallScreen ? { top: 30, right: 10, bottom: 90, left: 30 } : { top: 30, right: 30, bottom: 100, left: 50 }}
                padding={0.25}
                groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: isSmallScreen ? -50 : 0,
                    legend: 'Month',
                    legendPosition: 'middle',
                    legendOffset: 50,
                    tickValues: 5,
                    format: value => {
                        const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
                        return monthNames[value - 1];
                    },
                    style: { tick: { fill: textColor }, legend: { fill: textColor } }
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: isSmallScreen ? -50 : 0,
                    legendPosition: 'middle',
                    legendOffset: -40,
                    tickValues: 5,
                    style: { tick: { fill: textColor }, legend: { fill: textColor } }
                }}
                enableLabel={showLabels}
                labelSkipWidth={1}
                labelSkipHeight={1}
                labelTextColor={textColor}
                legends={isSmallScreen ? [
                    {
                        data: customLegendItems,
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: tabValue === 1 ? -10 : 10,
                        translateY: 80,
                        itemsSpacing: tabValue === 1 ? 10 : 2,
                        itemWidth: tabValue === 1 ? 80 : 75,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ],
                        textColor: textColor,
                        itemTextColor: textColor,
                        onClick: handleLegendClick
                    }
                ] : [
                    {
                        data: customLegendItems,
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 25,
                        translateY: 85,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ],
                        textColor: textColor,
                        itemTextColor: textColor,
                        onClick: handleLegendClick
                    }
                ]}
                theme={{
                    textColor: textColor,
                    axis: {
                        legend: {
                            text: {
                                fill: textColor
                            }
                        },
                        ticks: {
                            text: {
                                fill: textColor
                            }
                        }
                    }
                }}
                tooltip={customTooltip}
                defs={gradientDefs}
                fill={keys.map((name, index) => ({
                    match: { id: name },
                    id: `gradient${index + 1}`,
                }))}
            />
        </div>
    );
};

export default MonthlyBarChart;
