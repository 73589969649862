import React, { useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { customTooltip } from '../components/customTooltip';
import { gradientDefs } from '../components/gradientDefs';
import { useTheme } from '@mui/material';

const DailyBarChart = ({ isSmallScreen, selectedMac, selectedDateRange, showLabels, processedData, chartIsLoading, error, tabValue }) => {
    const themeMode = useSelector(({ global }) => global.mode);
    const theme = useTheme();
    const [activeLegends, setActiveLegends] = useState([]);

    // Check for 404 error
    if (error && error.status === 404) {
        return <NoDataAvailableMessage />;
    }

    if (chartIsLoading || !processedData) {
        return <Loading />;
    }

    const dateRange = selectedDateRange[0];

    const allDates = [];
    let currentDate = new Date(dateRange.startDate);
    while (currentDate <= dateRange.endDate) {
        allDates.push(format(currentDate, 'yyyy-MM-dd'));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    const filteredChartData = allDates.map(date => {
        const dateCounts = processedData?.[selectedMac]?.[date];
        if (dateCounts) {
            return {
                day: format(new Date(date), "do"),
                macAddress: selectedMac,
                ...dateCounts
            };
        } else if (processedData?.[selectedMac] && processedData[selectedMac][allDates[0]]) {
            const zeroCounts = Object.keys(processedData[selectedMac][allDates[0]]).reduce((acc, key) => {
                acc[key] = 0;
                return acc;
            }, {});
            return {
                day: format(new Date(date), "do"),
                macAddress: selectedMac,
                ...zeroCounts
            };
        } else {
            return null;
        }
    });

    const filteredChartDataWithoutNull = filteredChartData.filter(data => data !== null);

    if (filteredChartDataWithoutNull.length === 0) {
        return <NoDataAvailableMessage />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';
    const logicNames = Object.keys(filteredChartDataWithoutNull[0]).filter(key => key !== 'day' && key !== 'macAddress');

    const customLegendItems = logicNames.map((name, index) => ({
        id: name,
        label: name,
        color: `url(#gradient${index + 1})`,
    }));

    const handleLegendClick = (legendItem) => {
        setActiveLegends((prevActiveLegends) =>
            prevActiveLegends.includes(legendItem.id)
                ? prevActiveLegends.filter((item) => item !== legendItem.id)
                : [...prevActiveLegends, legendItem.id]
        );
    };

    const filteredKeys = logicNames.filter((name) => !activeLegends.includes(name));

    return (
        <div style={{
            height: '70vh',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '15px'
        }}>
            <ResponsiveBar
                data={filteredChartData}
                keys={filteredKeys}
                indexBy="day"
                margin={isSmallScreen ? { top: 30, right: 10, bottom: 90, left: 30 } : { top: 30, right: 30, bottom: 100, left: 50 }}
                padding={0.25}
                groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'nivo' }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: isSmallScreen ? -50 : 0,
                    legend: 'Day',
                    legendPosition: 'middle',
                    legendOffset: 45,
                    tickValues: 5,
                    style: {
                        tick: { fill: textColor },
                        legend: { fill: textColor }
                    }
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: 'middle',
                    legendOffset: -40,
                    tickValues: 5,
                    style: {
                        tick: { fill: textColor },
                        legend: { fill: textColor }
                    }
                }}
                enableLabel={showLabels}
                labelSkipWidth={1}
                labelSkipHeight={1}
                labelTextColor={textColor}
                legends={isSmallScreen ? [
                    {
                        data: customLegendItems,
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: tabValue === 1 ? -10 : 10,
                        translateY: 80,
                        itemsSpacing: tabValue === 1 ? 10 : 2,
                        itemWidth: tabValue === 1 ? 80 : 75,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ],
                        textColor: textColor,
                        itemTextColor: textColor,
                        onClick: handleLegendClick
                    }
                ] : [
                    {
                        data: customLegendItems,
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 25,
                        translateY: 85,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ],
                        textColor: textColor,
                        itemTextColor: textColor,
                        onClick: handleLegendClick
                    }
                ]}
                tooltip={customTooltip}
                motionConfig="slow"
                theme={{
                    tooltip: {
                        container: {
                            background: '#000',
                            color: '#fff',
                            fontSize: '14px',
                            borderRadius: '4px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
                            padding: '10px'
                        }
                    },
                    textColor: textColor,
                    axis: {
                        legend: {
                            text: {
                                fill: textColor
                            }
                        },
                        ticks: {
                            text: {
                                fill: textColor
                            }
                        }
                    }
                }}
                defs={gradientDefs}
                fill={logicNames.map((name, index) => ({
                    match: { id: name },
                    id: `gradient${index + 1}`,
                }))}
            />
        </div>
    );
};

export default DailyBarChart;
