import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useGetSitesQuery, useAddSiteMutation } from "../../services/api";
import useSelectedCompany from '../../hooks/useSelectedCompany';
import useCompanyOptions from '../../hooks/useCompanyOptions';
import AllSitesHeader from './AllSitesHeader';
import AllSitesTable from './AllSitesTable';
import AddSiteDialogBox from './AddSiteDialogBox';
import mainLocationsInQatar from '../../constants/mainLocations';
import ErrorSnackBar from '../sites/ErrorSnackBar';

const AllSites = () => {
    const companyOptions = useCompanyOptions();
    const { selectedCompany, handleCompanyChange } = useSelectedCompany({ companyOptions });
    const { data: sitesData = [], refetch } = useGetSitesQuery(selectedCompany || "");
    const [openDialog, setOpenDialog] = useState(false);
    const [siteData, setSiteData] = useState({ name: '', location: '', latitude: '', longitude: '' });
    const [addSite] = useAddSiteMutation();
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSiteData({
            ...siteData,
            [name]: value,
        });
    };

    const handleAddSite = async () => {
        try {
            const { name, location, latitude, longitude } = siteData;
            if (name && location && latitude && longitude) {
                await addSite({ ...siteData });
                handleCloseDialog();
                refetch();
                setSiteData({ name: '', location: '', latitude: '', longitude: '' });
                setSnackbar({ open: true, message: "Site added successfully", severity: "success" });
            } else {
                setSnackbar({ open: true, message: "Please fill in all the fields", severity: "error" });
            }
        } catch (error) {
            console.error('Error adding site:', error);
        }
    };

    return (
        <Box m="1.5rem 2.5rem">
            <AllSitesHeader 
                selectedCompany={selectedCompany}
                handleCompanyChange={handleCompanyChange}
                companyOptions={companyOptions}
                handleOpenDialog={handleOpenDialog}
            />
            <Box marginTop="1.5rem">
                <AllSitesTable sitesData={sitesData} selectedCompany={selectedCompany} />
            </Box>
            <AddSiteDialogBox
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                handleInputChange={handleInputChange}
                handleAddSite={handleAddSite}
                siteData={siteData}
                mainLocationsInQatar={mainLocationsInQatar}
                companyOptions={companyOptions}
            />
            <ErrorSnackBar
                open={snackbar.open}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </Box>
    );
}

export default AllSites;
