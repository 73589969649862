import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../services/api";
import { useDispatch } from "react-redux";
import { login, setId, setUsername, setCompany, setRole, setPermissions, setAssignedCompanies } from "../services/state/globalSlice";

const useLoginHook = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [inputs, setInputs] = useState({ email: '', password: '' });
    const [loginMutation] = useLoginMutation();

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setInputs(prevInputs => ({ ...prevInputs, [name]: value }));
    }, []);

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        try {
            const response = await loginMutation(inputs);
            if (response.data.message === 'Successfully Logged In') {
                const { user } = response.data;
                dispatch(login());
                dispatch(setId(user._id));
                dispatch(setUsername(user.name));
                dispatch(setCompany(user.company));
                dispatch(setRole(user.role));
                dispatch(setPermissions(user.permissions));
                dispatch(setAssignedCompanies(user.assignedCompanies));

                if (user.role === 'Super Admin') {
                    navigate('/homepage');
                } else {
                    navigate('/dashboard');
                }
            } else {
                throw new Error(response.data.message);
            }
        } catch (err) {
            if (err?.response?.status === 400 && err?.response?.data?.message === 'Invalid Email or Password') {
                setError('Invalid email or password');
            } else {
                setError('An error occurred while logging in. Please try again later.');
            }
        }
    }, [dispatch, inputs, loginMutation, navigate]);

    return {
        error,
        handleChange,
        handleSubmit,
        inputs
    };
};

export default useLoginHook;
