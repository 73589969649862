import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, useTheme } from '@mui/material';
import Slide from '@mui/material/Slide';
import { useAddCompanyMutation } from "../../services/api";
import ErrorSnackbar from '../../components/ErrorSnackbar';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddCompanyDialogBox = ({ openDialog, handleCloseDialog, refetch }) => {
    const theme = useTheme();
    const [companyName, setCompanyName] = useState('');
    const [industry, setIndustry] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [website, setWebsite] = useState('');
    const [addCompany] = useAddCompanyMutation();
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });

    const handleAddCompany = async () => {
        try {
            const newCompanyData = {
                name: companyName,
                industry: industry,
                address: {
                    addressLines: [address],
                    city: city,
                    country: country
                },
                contact: {
                    email: email,
                    phone: phone
                },
                website: website
            };
            const response = await addCompany(newCompanyData);
            if (!response.error) {
                setSnackbar({
                    open: true,
                    message: "Company added successfully!",
                    severity: "success"
                });
                handleCloseDialog();
                refetch();
            } else {
                setSnackbar({
                    open: true,
                    message: "Error adding company. Please try again.",
                    severity: "error"
                });
            }
        } catch (error) {
            console.error('Error adding company:', error);
        }
    };

    const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

    return (
        <>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                onClose={handleCloseDialog}
                aria-describedby="add-device-dialog-description"
                PaperProps={{ sx: { backgroundColor: theme.palette.background.paper } }}
            >
                <DialogTitle sx={{ color: theme.palette.secondary.main }} variant="h2" fontWeight="bold">
                    Add Company
                </DialogTitle>
                <DialogContent sx={{ width: '500px' }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="companyName"
                        label="Name"
                        fullWidth
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="industry"
                        label="Industry"
                        fullWidth
                        value={industry}
                        onChange={(e) => setIndustry(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="address"
                        label="Address"
                        fullWidth
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="city"
                        label="City"
                        fullWidth
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="country"
                        label="Country"
                        fullWidth
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="email"
                        label="Email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="phone"
                        label="Phone"
                        fullWidth
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="website"
                        label="Website"
                        fullWidth
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDialog}
                        variant="contained"
                        sx={{
                            color: theme.palette.text.black,
                            backgroundColor: theme.palette.primary.main,
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: theme.palette.secondary.main,
                            },
                            '&:focus': {
                                outline: 'none',
                            },
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleAddCompany}
                        variant="contained"
                        sx={{
                            color: theme.palette.text.black,
                            backgroundColor: theme.palette.primary.main,
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: theme.palette.secondary.main,
                            },
                            '&:focus': {
                                outline: 'none',
                            },
                        }}
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
            <ErrorSnackbar open={snackbar.open} message={snackbar.message} severity={snackbar.severity} handleClose={handleSnackbarClose} />
        </>
    );
}

export default AddCompanyDialogBox;
