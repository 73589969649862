import React, { useState, useMemo } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel } from '@mui/material';

const AllDevicesTable = ({ devicesData = [], selectedCompany }) => {

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(property);
    };

    const filteredDevicesData = useMemo(() => {
        if (!devicesData || !Array.isArray(devicesData)) {
            return [];
        }

        if (!selectedCompany) {
            return devicesData;
        } else {
            return devicesData.filter(device => device.company === selectedCompany);
        }
    }, [devicesData, selectedCompany]);

    const sortedData = useMemo(() => {
        if (!filteredDevicesData || !Array.isArray(filteredDevicesData)) {
            return [];
        }

        const sortedDevices = [...filteredDevicesData].sort((a, b) => {
            if (order === 'asc') {
                return a[orderBy] > b[orderBy] ? 1 : -1;
            } else {
                return b[orderBy] > a[orderBy] ? 1 : -1;
            }
        });
        return sortedDevices;
    }, [filteredDevicesData, orderBy, order]);

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString();
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {['name', 'company', 'type', 'mac', 'firmware', 'ip', 'status', 'site', 'createdAt', 'updatedAt'].map(header => (
                        <TableCell key={header}>
                            <TableSortLabel
                                active={orderBy === header}
                                direction={orderBy === header ? order : 'asc'}
                                onClick={createSortHandler(header)}
                            >
                                {header.charAt(0).toUpperCase() + header.slice(1).replace(/([A-Z])/g, ' $1')}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {sortedData.map(device => (
                    <TableRow key={device._id}>
                        <TableCell>{device.name}</TableCell>
                        <TableCell>{device.company}</TableCell>
                        <TableCell>{device.type}</TableCell>
                        <TableCell>{device.mac}</TableCell>
                        <TableCell>{device.firmware}</TableCell>
                        <TableCell>{device.ip}</TableCell>
                        <TableCell>
                            <span style={{ color: device.status === 'ONLINE' ? 'green' : 'red', fontWeight: 'bold' }}>
                                {device.status}
                            </span>
                        </TableCell>
                        <TableCell>{device.site}</TableCell>
                        <TableCell>{formatDate(device.createdAt)}</TableCell>
                        <TableCell>{formatDate(device.updatedAt)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default AllDevicesTable;
