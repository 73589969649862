import React from 'react';
import { Box, useTheme, Button, Typography } from '@mui/material';
import Header from '../../components/Header';
import CompanySelector from '../../components/CompanySelector';
import { AddBoxOutlined } from '@mui/icons-material';

const CompanyHeader = ({ companyOptions, selectedCompany, handleCompanyChange, handleOpenDialog, getSelectedCompanyLabel }) => {
    const theme = useTheme();

    return (
        <Box width="100%">
            <Box 
                display="flex" 
                justifyContent="space-between" 
                width="100%" 
                backgroundColor={theme.palette.background.paper} 
                borderRadius='15px' 
                padding="0.5rem"
            >
                <Header title="COMPANIES" subtitle="View all registered company details" />
                <Box display="flex" alignItems="center" gap="1rem">
                    <CompanySelector
                        companyOptions={companyOptions}
                        selectedCompany={selectedCompany}
                        handleCompanyChange={handleCompanyChange}
                    />
                    <Button
                        onClick={handleOpenDialog}
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.text.black,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "13px 20px",
                            minWidth: "175px",
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                                backgroundColor: theme.palette.secondary.main,
                            },
                            '&:focus': {
                                outline: 'none',
                            },
                        }}
                    >
                        <AddBoxOutlined sx={{ marginRight: "10px" }} />
                        Add Company
                    </Button>
                </Box>
            </Box>
            <Box
                marginTop="1rem"
                backgroundColor={theme.palette.background.paper}
                borderRadius='15px'
                padding="0.5rem"
            >
                <Typography variant="h2" component="div" sx={{ color: theme.palette.secondary.main, fontWeight: "bold" }}>
                    {getSelectedCompanyLabel}
                </Typography>
            </Box>
        </Box>
    );
};

export default CompanyHeader;
