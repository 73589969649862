import React from 'react';
import { Typography, useTheme, Grid } from '@mui/material';
import Loading from '../../components/Loading';
import dayjs from 'dayjs';
import TopDayTable from '../../charts/reportCharts/TopDayTable';
import TopDayPieChart from '../../charts/reportCharts/TopDayPieChart';
import TopWeekTable from '../../charts/reportCharts/TopWeekTable';
import TopWeekPieChart from '../../charts/reportCharts/TopWeekPieChart';
import ReportWeeklyChart from '../../charts/reportCharts/ReportWeeklyChart';
import ReportDailyChart from '../../charts/reportCharts/ReportDailyChart';
import ReportMonthlyChart from '../../charts/reportCharts/ReportMonthlyChart';
import WeeklyCountTable from '../../charts/reportCharts/WeeklyCountTable';
import MonthlyCountTable from '../../charts/reportCharts/MonthlyCountTable';
import './style.css';

const ReportContent = ({
    selectedSensor,
    isSmallScreen,
    extremeData,
    extremeDataLoading,
    selectedMonth,
    selectedYear,
    monthlyProcessedData,
    monthlyDataIsLoading,
}) => {
    
    const theme = useTheme();

    const highestData = extremeData && selectedSensor
        ? extremeData[selectedSensor]?.highestDay?.[`${selectedYear}-${selectedMonth.toString().padStart(2, '0')}`]
        : null;
    
    const lowestData = extremeData && selectedSensor
        ? extremeData[selectedSensor]?.lowestDay?.[`${selectedYear}-${selectedMonth.toString().padStart(2, '0')}`]
        : null;
    
    const highestWeekData = extremeData && selectedSensor
        ? extremeData[selectedSensor]?.highestWeekForMonth?.[`${selectedYear}`]?.[`${selectedMonth}`]
        : null;

    const lowestWeekData = extremeData && selectedSensor
        ? extremeData[selectedSensor]?.lowestWeekForMonth?.[`${selectedYear}`]?.[`${selectedMonth}`]
        : null;
    
    const dailyData = monthlyProcessedData
        ? monthlyProcessedData['dailyData']
        : null;
    
    const weeklyTableData = monthlyProcessedData
        ? monthlyProcessedData['weeklyData']
        : null;
    
    const monthlyTableData = monthlyProcessedData
        ? monthlyProcessedData['monthlyData']
        : null;

    if (!selectedSensor) {
        return <Loading />;
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid container item xs={12} justifyContent="center" spacing={2} mt={isSmallScreen ? 0 : 5}>
                    <Grid item xs={isSmallScreen ? 12 : 6}>
                        <TopDayTable
                            title="PEAK DAY"
                            data={highestData ? [highestData] : []}
                            isLoading={extremeDataLoading}
                            selectedSensor={selectedSensor}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                        />
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : 6}>
                        <TopDayPieChart
                            data={highestData ? [highestData] : []}
                            isLoading={extremeDataLoading}
                            selectedSensor={selectedSensor}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                            title="PEAK DAY"
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="center" spacing={2} mt={5}>
                    <Grid item xs={isSmallScreen ? 12 : 6}>
                        <TopDayPieChart
                            data={lowestData ? [lowestData] : []}
                            isLoading={extremeDataLoading}
                            selectedSensor={selectedSensor}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                            title="LOWEST DAY"
                        />
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : 6}>
                        <TopDayTable
                            title="LOWEST DAY"
                            data={lowestData ? [lowestData] : []}
                            isLoading={extremeDataLoading}
                            selectedSensor={selectedSensor}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="center" spacing={2} mt={5}>
                    <Grid item xs={isSmallScreen ? 12 : 6}>
                        <TopWeekTable
                            title="HIGHEST WEEK"
                            headings={["Logic Name", "In", "Out", "Day"]}
                            data={highestWeekData ? [highestWeekData] : []}
                            isLoading={extremeDataLoading}
                            selectedSensor={selectedSensor}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                        />
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : 6}>
                        <TopWeekPieChart
                            data={highestWeekData ? [highestWeekData] : []}
                            isLoading={extremeDataLoading}
                            selectedSensor={selectedSensor}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                            title="PEAK WEEK"
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="center" spacing={2} mt={5}>
                    <Grid item xs={isSmallScreen ? 12 : 6}>
                        <TopWeekPieChart
                            data={lowestWeekData ? [lowestWeekData] : []}
                            isLoading={extremeDataLoading}
                            selectedSensor={selectedSensor}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                            title="LOWEST WEEK"
                        />
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : 6}>
                        <TopWeekTable
                            title="LOWEST WEEK"
                            headings={["Logic Name", "In", "Out", "Day"]}
                            data={lowestWeekData ? [lowestWeekData] : []}
                            isLoading={extremeDataLoading}
                            selectedSensor={selectedSensor}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} mt={5}>
                    <Typography variant="h2" component="div" m={3} sx={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}>
                        DAILY COUNT
                    </Typography>
                    {selectedSensor ? (
                        <ReportDailyChart
                            dailyData={dailyData || []}
                            dailyLoading={monthlyDataIsLoading}
                            selectedMac={selectedSensor}
                            selectedDateRange={[
                                {
                                    startDate: dayjs().set('year', selectedYear).set('month', selectedMonth - 1).set('date', 1),
                                    endDate: dayjs().set('year', selectedYear).set('month', selectedMonth - 1).endOf('month')
                                }
                            ]}
                        /> 
                    ) : (
                        <Loading />
                    )}
                </Grid>
                <Grid item xs={isSmallScreen ? 12 : 6}>
                    <Typography variant="h2" component="div" m={3} sx={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}>
                        Weekly Count
                    </Typography>
                    {selectedSensor ? (
                        <ReportWeeklyChart
                            weeklyData={weeklyTableData || []}
                            weeklyLoading={monthlyDataIsLoading}
                            selectedMac={selectedSensor}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                        />
                    ) : (
                        <Loading />
                    )}
                </Grid>
                <Grid item xs={isSmallScreen ? 12 : 6}>
                    <Typography variant="h2" component="div" m={3} sx={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}>
                        Month Count
                    </Typography>
                    {selectedSensor ? (
                        <ReportMonthlyChart
                            monthlyData={monthlyTableData || []}
                            monthlyLoading={monthlyDataIsLoading}
                            selectedSensor={selectedSensor}
                            selectedYear={selectedYear}
                        />
                    ) : (
                        <Loading />
                    )}
                </Grid>
                <Grid item xs={isSmallScreen ? 12 : 6} mt={5} className="invisible-scroll">
                    <WeeklyCountTable
                        title="WEEKLY COUNTS"
                        data={weeklyTableData || []}
                        isLoading={monthlyDataIsLoading}
                        selectedSensor={selectedSensor}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                    />
                </Grid>
                <Grid item xs={isSmallScreen ? 12 : 6} mb={5} mt={5} className="invisible-scroll" style={{ height: '400px' }}>
                    <MonthlyCountTable
                        title="MONTH COUNTS"
                        data={monthlyTableData || []}
                        isLoading={monthlyDataIsLoading}
                        selectedSensor={selectedSensor}
                        selectedYear={selectedYear}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default ReportContent;
