import React, { useState, useEffect, useCallback } from 'react';
import WeeklyHeader from './WeeklyHeader';
import useSelectedSensor from '../../hooks/useSelectedSensor';
import useSensorOptions from '../../hooks/useSensorOptions';
import useCompanyOptions from '../../hooks/useCompanyOptions';
import useSelectedCompany from '../../hooks/useSelectedCompany';
import WeeklyContent from './WeeklyContent';
import { Box, Grid, Paper } from '@mui/material';
import { useGetDevicesQuery, useWeeklyDataQuery, useAdultnChildWeeklyDataQuery } from '../../services/api';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import WeeklyFilterBox from './WeeklyFilterBox';
import WeeklyLabel from './WeeklyLabel';

const Weekly = () => {
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });

    // Redux state
    const { company, role } = useSelector((state) => state.global);

    const companyOptions = useCompanyOptions();

    const isSuperOrPowerAdmin = role === 'Super Admin' || role === 'Power Admin';

    // State variables
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [tempSelectedMonth, setTempSelectedMonth] = useState(null);
    const [tempSelectedYear, setTempSelectedYear] = useState(null);
    const [showLabels, setShowLabels] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    // Custom hooks
    const { selectedCompany, handleCompanyChange } = useSelectedCompany({ companyOptions });
    const sensorOptions = useSensorOptions(isSuperOrPowerAdmin ? selectedCompany : company);
    const { selectedSensor, handleSensorChange, getSelectedSensorLabel } = useSelectedSensor(sensorOptions, isSuperOrPowerAdmin ? selectedCompany : company);
    
    const { data: devicesData } = useGetDevicesQuery(company);
    const { data: weeklyData, isLoading: weeklyLoading, error: weeklyError } = useWeeklyDataQuery(
        { mac: selectedSensor },
        { skip: tabValue !== 0 }
    );
    const { data: adultChildData, isLoading: adultChildLoading, error: adultChildError } = useAdultnChildWeeklyDataQuery(
        { mac: selectedSensor },
        { skip: tabValue !== 1 }
    );

    // Determine which data to use based on the selected tab
    const processedData = tabValue === 0 ? weeklyData : adultChildData;
    const isLoading = tabValue === 0 ? weeklyLoading : adultChildLoading;
    const error = tabValue === 0 ? weeklyError : adultChildError;

    const handlePopoverOpen = useCallback((event) => setAnchorEl(event.currentTarget), []);
    const handlePopoverClose = useCallback(() => setAnchorEl(null), []);
    const handleDateChange = useCallback((date) => {
        setTempSelectedMonth(date.month() + 1);
        setTempSelectedYear(date.year());
    }, []);

    const handleApply = useCallback(() => {
        setSelectedMonth(tempSelectedMonth);
        setSelectedYear(tempSelectedYear);
        handlePopoverClose();
    }, [tempSelectedMonth, tempSelectedYear, handlePopoverClose]);

    const handleShowLabelsChange = useCallback((event) => {
        setShowLabels(event.target.checked);
    }, []);

    const handleTabChange = (newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();
        setSelectedMonth(currentMonth);
        setSelectedYear(currentYear);
    }, []);

    // JSX
    return (
        <Box m={isSmallScreen ? "0.5rem" : "1rem 2.5rem"}>
            <Grid container spacing={2} paddingBottom={3}>
                <Grid item xs={12} md={10}>
                    <WeeklyHeader isSmallScreen={isSmallScreen} />
                    <WeeklyLabel
                        sensorLabel={getSelectedSensorLabel()}
                        isSmallScreen={isSmallScreen}
                        tabValue={tabValue}
                        onTabChange={handleTabChange}
                    />
                    <WeeklyContent
                        selectedSensor={selectedSensor}
                        showLabels={showLabels}
                        devicesData={devicesData}
                        processedData={processedData}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                        error={error}
                        isLoading={isLoading}
                        isSmallScreen={isSmallScreen}
                        sensorLabel={getSelectedSensorLabel()}
                        tabValue={tabValue}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Paper sx={{ position: 'sticky', top: 20, height: 'auto', overflow: 'auto', borderRadius: '15px' }}>
                        <WeeklyFilterBox
                            sensorLabel={getSelectedSensorLabel()}
                            isSmallScreen={isSmallScreen}
                            showLabels={showLabels}
                            onShowLabelsChange={handleShowLabelsChange}
                            sensorOptions={sensorOptions}
                            selectedSensor={selectedSensor}
                            handleDateChange={handleDateChange}
                            handleApply={handleApply}
                            handleSensorChange={handleSensorChange}
                            handlePopoverOpen={handlePopoverOpen}
                            handlePopoverClose={handlePopoverClose}
                            anchorEl={anchorEl}
                            tempSelectedMonth={tempSelectedMonth}
                            tempSelectedYear={tempSelectedYear}
                            companyOptions={companyOptions}
                            selectedCompany={isSuperOrPowerAdmin ? selectedCompany : null}
                            handleCompanyChange={isSuperOrPowerAdmin ? handleCompanyChange : null}
                            isSuperAdmin={isSuperOrPowerAdmin}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Weekly;
