import React from "react";
import styles from "./styles.module.css";
import useSignupHook from "../../hooks/useSignupHook";
import backgroundIMG from "../../assets/background.jpeg";
import ErrorSnackbar from "../../components/ErrorSnackbar";

const Signup = () => {
  const {
    error,
    handleChange,
    handleSubmit,
    inputs,
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    handleSnackbarClose
  } = useSignupHook();

  return (
    <div
      className={styles.login_container}
      style={{ backgroundImage: `url(${backgroundIMG})` }}
    >
      <div className={styles.login_form_container}>
        <div className={styles.left}>
          <form className={styles.form_container} onSubmit={handleSubmit}>
            <h1>CREATE ACCOUNT</h1>
            <input
              type="email"
              placeholder="Email"
              name="email"
              onChange={handleChange}
              value={inputs.email}
              required
              className={styles.input}
            />
            <input
              type="password"
              placeholder="Password"
              name="password"
              onChange={handleChange}
              value={inputs.password}
              required
              className={styles.input}
            />
            <input
              type="password"
              placeholder="Confirm Password"
              name="confirmPassword"
              onChange={handleChange}
              value={inputs.confirmPassword}
              required
              className={styles.input}
            />
            <input
              type="text"
              placeholder="Country"
              name="country"
              onChange={handleChange}
              value={inputs.country}
              required
              className={styles.input}
            />
            <input
              type="tel"
              placeholder="Phone Number"
              name="phoneNumber"
              onChange={handleChange}
              value={inputs.phoneNumber}
              required
              className={styles.input}
            />
            {error && <div className={styles.error_msg}>{error}</div>}
            <button type="submit" className={styles.green_btn}>
              Activate Account
            </button>
          </form>
        </div>
      </div>
      <ErrorSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </div>
  );
};

export default Signup;
