import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { themeSettings } from './theme';
import Login from './pages/login';
import Signup from './pages/signup';
import Layout from './pages/layout';
import Homepage from './pages/homepage';
import Companies from './pages/companies';
import Licenses from './pages/licenses';
import Dashboard from './pages/dashboard';
import Devices from './pages/devices';
import Sites from './pages/sites';
import Users from './pages/users';
import EditData from './pages/edit data';
import Notifications from './pages/notifications';
import Overview from './pages/overview';
import Hourly from './pages/hourly';
import Daily from './pages/daily';
import Weekly from './pages/weekly';
import Monthly from './pages/monthly';
import Yearly from './pages/yearly';
import Report from './pages/report';
import Settings from './pages/settings';
import AdminManagement from './pages/admin management';
import AllUsers from './pages/all users';
import AllDevices from './pages/all devices';
import AllSites from './pages/all sites';
import AllLogics from './pages/all logics';

function AppWrapper() {
  return <App />;
}

function App({ location }) {
  const { mode, isLoggedIn } = useSelector((state) => state.global);
  const theme = createTheme(themeSettings(mode));

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (isLoggedIn) {
        localStorage.clear();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isLoggedIn]);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            {!isLoggedIn && (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </>
            )}
            {isLoggedIn && (
              <Route element={<Layout />}>
                <Route path="homepage" element={<Homepage />} />
                <Route path="companies" element={<Companies />} />
                <Route path="licenses" element={<Licenses />} />
                <Route path="admin-management" element={<AdminManagement />} />
                <Route path="all-users" element={<AllUsers />} />
                <Route path="all-devices" element={<AllDevices />} />
                <Route path="all-sites" element={<AllSites />} />
                <Route path="all-logics" element={<AllLogics />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="devices" element={<Devices />} />
                <Route path="sites" element={<Sites />} />
                <Route path="notifications" element={<Notifications />} />
                <Route path="overview" element={<Overview />} />
                <Route path="hourly" element={<Hourly />} />
                <Route path="daily" element={<Daily />} />
                <Route path="weekly" element={<Weekly />} />
                <Route path="monthly" element={<Monthly />} />
                <Route path="yearly" element={<Yearly />} />
                <Route path="report" element={<Report />} />
                <Route path="users" element={<Users />} />
                <Route path="edit-data" element={<EditData />} />
                <Route path="settings" element={<Settings />} />
              </Route>
            )}
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default AppWrapper;