import React, { useState, useCallback } from 'react';
import { Box, Grid, Paper } from '@mui/material';
import HourlyContent from './HourlyContent';
import useSelectedSensor from '../../hooks/useSelectedSensor';
import useSensorOptions from '../../hooks/useSensorOptions';
import useCompanyOptions from '../../hooks/useCompanyOptions';
import useSelectedCompany from '../../hooks/useSelectedCompany';
import { useHourlyDataQuery, useAdultnChildHourlyDataQuery } from '../../services/api';
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';
import HourlyHeader from './HourlyHeader';
import HourlyFilterBox from './HourlyFilterBox';
import HourlyLabel from './HourlyLabel';
import { useSelector } from 'react-redux';

const Hourly = () => {
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [showLabels, setShowLabels] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const { company, role } = useSelector(state => state.global);

    const companyOptions = useCompanyOptions();

    const isSuperOrPowerAdmin = role === 'Super Admin' || role === 'Power Admin';

    const { selectedCompany, handleCompanyChange } = useSelectedCompany({ companyOptions });
    const sensorOptions = useSensorOptions(isSuperOrPowerAdmin ? selectedCompany : company);
    const { selectedSensor, handleSensorChange, getSelectedSensorLabel } = useSelectedSensor(sensorOptions, isSuperOrPowerAdmin ? selectedCompany : company);

    const startDate = selectedDate.clone().startOf('day').toISOString();
    const endDate = selectedDate.clone().endOf('day').toISOString();

    // Call queries based on tab value
    const { data: hourlyData, isLoading: hourlyLoading, error: hourlyError } = useHourlyDataQuery(
        { mac: selectedSensor, startDate, endDate },
        { skip: tabValue !== 0 }
    );

    const { data: adultChildData, isLoading: adultChildLoading, error: adultChildError } = useAdultnChildHourlyDataQuery(
        { mac: selectedSensor, startDate, endDate },
        { skip: tabValue !== 1 }
    );

    // Determine which data to use based on the selected tab
    const processedData = tabValue === 0 ? hourlyData : adultChildData;
    const chartIsLoading = tabValue === 0 ? hourlyLoading : adultChildLoading;
    const error = tabValue === 0 ? hourlyError : adultChildError;

    const handlePopoverOpen = useCallback((event) => setAnchorEl(event.currentTarget), []);
    const handlePopoverClose = useCallback(() => setAnchorEl(null), []);
    const handleDateChange = useCallback((date) => {
        setSelectedDate(date);
        handlePopoverClose();
    }, [handlePopoverClose]);

    const handleShowLabelsChange = (event) => {
        setShowLabels(event.target.checked);
    };

    const handleTabChange = (newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box m={isSmallScreen ? "0.5rem" : "1rem 2.5rem"}>
            <Grid container spacing={2} paddingBottom={3}>
                <Grid item xs={12} md={10}>
                    <HourlyHeader isSmallScreen={isSmallScreen} />
                    <HourlyLabel
                        sensorLabel={getSelectedSensorLabel()}
                        isSmallScreen={isSmallScreen}
                        tabValue={tabValue}
                        onTabChange={handleTabChange}
                    />
                    <HourlyContent
                        selectedSensor={selectedSensor}
                        showLabels={showLabels}
                        processedData={processedData}
                        chartIsLoading={chartIsLoading}
                        error={error}
                        isSmallScreen={isSmallScreen}
                        sensorLabel={getSelectedSensorLabel()}
                        tabValue={tabValue}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Paper sx={{ position: 'sticky', top: 20, height: 'auto', overflow: 'auto', borderRadius: '15px' }}>
                        <HourlyFilterBox
                            sensorLabel={getSelectedSensorLabel()}
                            isSmallScreen={isSmallScreen}
                            showLabels={showLabels}
                            onShowLabelsChange={handleShowLabelsChange}
                            sensorOptions={sensorOptions}
                            selectedSensor={selectedSensor}
                            handleSensorChange={handleSensorChange}
                            selectedDate={selectedDate}
                            handleDateChange={handleDateChange}
                            handlePopoverOpen={handlePopoverOpen}
                            handlePopoverClose={handlePopoverClose}
                            anchorEl={anchorEl}
                            companyOptions={companyOptions}
                            selectedCompany={isSuperOrPowerAdmin ? selectedCompany : null}
                            handleCompanyChange={isSuperOrPowerAdmin ? handleCompanyChange : null}
                            isSuperAdmin={isSuperOrPowerAdmin}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Hourly;