import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Header from '../../components/Header';
import CompanySelector from '../../components/CompanySelector';

const AllLogicsHeader = ({ selectedCompany, handleCompanyChange, companyOptions }) => {
    const theme = useTheme();

    return (
        <Box display="flex" flexDirection="column" gap="1rem">
            <Box 
                display="flex" 
                justifyContent="space-between" 
                width="100%" 
                backgroundColor={theme.palette.background.paper} 
                borderRadius='15px' 
                padding="0.5rem"
            >
                <Header title="ALL LOGICS" subtitle="View all registered logic details for companies" />
                <Box display="flex" alignItems="center" gap="1rem">
                    <CompanySelector 
                        selectedCompany={selectedCompany}
                        handleCompanyChange={handleCompanyChange}
                        companyOptions={companyOptions}
                    />
                </Box>
            </Box>
            {selectedCompany && (
                <Box
                    backgroundColor={theme.palette.background.paper}
                    borderRadius='15px'
                    padding="0.5rem"
                >
                    <Typography variant="h2" component="div" sx={{ color: theme.palette.secondary.main, fontWeight: "bold" }}>
                        {selectedCompany}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default AllLogicsHeader;
