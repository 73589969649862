import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Slide } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddPackageDialogBox = ({ open, handleCloseDialog, packageData, handleInputChange, handleAddPackage }) => {
    const theme = useTheme();

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleCloseDialog}
            aria-describedby="add-package-dialog-description"
            PaperProps={{
                sx: { 
                backgroundColor: theme.palette.background.paper, 
                maxWidth: '500px'
                }
            }}
        >
        <DialogTitle variant='h2' fontWeight='bold' sx={{ color: theme.palette.secondary.main }}>
            Add New Package
        </DialogTitle>
        <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label="Package Name"
                fullWidth
                value={packageData.name}
                onChange={handleInputChange}
            />
            <TextField
                margin="dense"
                id="description"
                name="description"
                label="Description"
                fullWidth
                value={packageData.description}
                onChange={handleInputChange}
            />
            <TextField
                margin="dense"
                id="durationInDays"
                name="durationInDays"
                label="Duration (Days)"
                fullWidth
                value={packageData.durationInDays}
                onChange={handleInputChange}
            />
        </DialogContent>
        <DialogActions sx={{ paddingBottom: '15px', paddingTop: '0px' }}>
            <Button
                onClick={handleCloseDialog}
                variant="contained"
                sx={{
                    color: theme.palette.text.black,
                    backgroundColor: theme.palette.primary.main,
                    fontWeight: 'bold',
                    '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    },
                    '&:focus': {
                    outline: 'none',
                    },
                }}
            >
                Cancel
            </Button>
            <Button
                onClick={handleAddPackage}
                variant="contained"
                sx={{
                    color: theme.palette.text.black,
                    backgroundColor: theme.palette.primary.main,
                    fontWeight: 'bold',
                    '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    },
                    '&:focus': {
                    outline: 'none',
                    },
                }}
            >
                Add
            </Button>
        </DialogActions>
        </Dialog>
    );
};

export default AddPackageDialogBox;
