import React from 'react';
import { Box, Typography, useTheme, Button } from '@mui/material';
import Header from '../../components/Header';
import CompanySelector from '../../components/CompanySelector';
import { AddBoxOutlined } from '@mui/icons-material';

const LicensesHeader = ({ selectedCompany, handleCompanyChange, companyOptions, handleOpenPackageDialog }) => {
    const theme = useTheme();

    return (
        <Box display="flex" flexDirection="column" gap="1rem">
            <Box 
                display="flex" 
                justifyContent="space-between" 
                width="100%" 
                backgroundColor={theme.palette.background.paper} 
                borderRadius='15px' 
                padding="0.5rem"
            >
                <Header title="LICENSES" subtitle="View all registered license details for companies" />
                <Box display="flex" alignItems="center" gap="1rem">
                    <CompanySelector 
                        selectedCompany={selectedCompany}
                        handleCompanyChange={handleCompanyChange}
                        companyOptions={companyOptions}
                    />
                    <Button
                        onClick={handleOpenPackageDialog}
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.text.black,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "13px 20px",
                            minWidth: "175px",
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                                backgroundColor: theme.palette.secondary.main,
                            },
                            '&:focus': {
                                outline: 'none',
                            },
                        }}
                    >
                        <AddBoxOutlined sx={{ marginRight: "8px" }} />
                        Add New Package
                    </Button>
                </Box>
            </Box>
            {selectedCompany && (
                <Box
                    backgroundColor={theme.palette.background.paper}
                    borderRadius='15px'
                    padding="0.5rem"
                >
                    <Typography variant="h2" component="div" sx={{ color: theme.palette.secondary.main, fontWeight: "bold" }}>
                        {selectedCompany}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default LicensesHeader;
