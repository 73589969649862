import React from 'react';
import { useSelector } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import Loading from '../../components/Loading';
import { gradientDefs } from '../components/gradientDefs';
import CustomTooltipPie from '../components/customTooltipPie.js';

const TopWeekPieChart = ({ data, isLoading, selectedSensor, title }) => {
    const themeMode = useSelector(({ global }) => global.mode);

    if (isLoading) {
        return <Loading />
    }

    // Check for data availability
    if (!data || !selectedSensor) {
        return <NoDataAvailableMessage />;
    }

    // Assuming data is an array with one object
    const dataObject = data[0] || {};

    if (!dataObject || Object.keys(dataObject).length === 0) {
        return <NoDataAvailableMessage />;
    }

    // Prepare pie chart data
    const filteredKeys = Object.keys(dataObject).filter(key => key !== 'Total' && key !== 'week');
    const pieData = filteredKeys.map(key => ({
        id: key,
        value: dataObject[key],
    }));

    const week = dataObject.week;

    // Define rules to apply gradients
    const fill = pieData.map((_, index) => {
        if (index === 0) {
            return { match: { id: pieData[index].id }, id: 'gradient1' };
        } else if (index === 1) {
            return { match: d => d.id === pieData[index].id, id: 'gradient2' };
        } else if (index === 2) {
            return { match: d => d.id === pieData[index].id, id: 'gradient3' };
        } else {
            return { match: '*', id: 'gradient4' };
        }
    });

    const customLegendItems = fill.map((gradient, index) => ({
        id: pieData[index].id,
        label: pieData[index].id,
        color: `url(#${gradient.id})`,
    }));

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    return (
        <div style={{ position: 'relative', height: 270 }}>
            <ResponsivePie
                data={pieData}
                margin={{ top: 20, left: 0, right: 0, bottom: 20 }}
                startAngle={-150}
                innerRadius={0.65}
                activeOuterRadiusOffset={8}
                arcLinkLabelsTextColor={textColor}
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={textColor}
                arcLinkLabel="value"
                enableArcLabels={false}
                arcLabelsTextColor={{ from: textColor, modifiers: [['darker', 2]] }}
                defs={gradientDefs}
                fill={fill}
                tooltip={({ datum }) => <CustomTooltipPie datum={datum} />}
                legends={[
                    {
                        data: customLegendItems,
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 50,
                        itemsSpacing: 10,
                        itemWidth: 100,
                        itemHeight: 18,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 18,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
            />
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', width: '100%', pointerEvents: 'none' }}>
                <span style={{ fontSize: 16, fontWeight: 'bold' }}>{title}<br />Week {week}</span>
            </div>
        </div>
    );
};

export default TopWeekPieChart;
