import React, { useState, useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { useSelector } from 'react-redux';
import { gradientDefs } from '../components/gradientDefs';
import { customTooltip } from '../components/customTooltip';
import { useTheme } from '@mui/material';

const HourlyBarChart = ({ selectedMac, showLabels, isSmallScreen, processedData, chartIsLoading, error, tabValue }) => {
    const [activeLegends, setActiveLegends] = useState([]);
    const themeMode = useSelector(({ global }) => global.mode);
    const theme = useTheme();

    const hourlyData = useMemo(() => {
        if (processedData && processedData[selectedMac]) {
            return processedData[selectedMac];
        }
        return null;
    }, [processedData, selectedMac]);

    const allBarNames = useMemo(() => {
        if (hourlyData) {
            const barNames = Array.from(new Set(Object.values(hourlyData).flatMap(hourlyCounts => Object.keys(hourlyCounts))));
            // Sort bar names in alphabetical and numerical order
            return barNames.sort((a, b) => {
                const aNum = parseFloat(a);
                const bNum = parseFloat(b);
                if (!isNaN(aNum) && !isNaN(bNum)) {
                    return aNum - bNum;
                }
                return a.localeCompare(b);
            });
        }
        return [];
    }, [hourlyData]);

    const chartData = useMemo(() => {
        const data = Array.from({ length: 24 }, (_, hour) => {
            const hourData = { hour: `${hour}:00` };
            allBarNames.forEach(name => hourData[name] = 0);
            return hourData;
        });

        if (hourlyData) {
            Object.entries(hourlyData).forEach(([hour, counts]) => {
                const index = parseInt(hour);
                if (!isNaN(index) && index >= 0 && index < 24) {
                    Object.entries(counts).forEach(([logic, count]) => {
                        data[index][logic] = count;
                    });
                }
            });
        }

        return data;
    }, [hourlyData, allBarNames]);

    if (chartIsLoading) {
        return <Loading />;
    }

    if (error || error?.status === 404 || !hourlyData || Object.keys(hourlyData).length === 0) {
        return <NoDataAvailableMessage />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const customLegendItems = allBarNames.map((name, index) => ({
        id: name,
        label: name,
        color: `url(#gradient${index + 1})`,
    }));

    const handleLegendClick = (legendItem) => {
        setActiveLegends((prevActiveLegends) =>
            prevActiveLegends.includes(legendItem.id)
                ? prevActiveLegends.filter((item) => item !== legendItem.id)
                : [...prevActiveLegends, legendItem.id]
        );
    };

    const filteredKeys = allBarNames.filter((name) => !activeLegends.includes(name));

    return (
        <div style={{
            height: '70vh',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '15px'
        }}>
            <ResponsiveBar
                data={chartData}
                keys={filteredKeys}
                indexBy="hour"
                margin={isSmallScreen ? { top: 30, right: 10, bottom: 90, left: 30 } : { top: 30, right: 30, bottom: 100, left: 50 }}
                padding={0.25}
                groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: isSmallScreen ? -50 : 0,
                    legendPosition: 'middle',
                    legendOffset: 45,
                    tickValues: 1,
                    style: {
                        tick: { fill: textColor },
                        legend: { fill: textColor }
                    },
                    legend: 'Hour'
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: 'middle',
                    legendOffset: -40,
                    tickValues: 5,
                    style: {
                        tick: { fill: textColor },
                        legend: { fill: textColor }
                    },
                }}
                enableLabel={showLabels}
                labelSkipWidth={1}
                labelSkipHeight={1}
                labelTextColor={textColor}
                legends={isSmallScreen ? [
                    {
                        data: customLegendItems,
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: tabValue === 1 ? -10 : 10,
                        translateY: 80,
                        itemsSpacing: tabValue === 1 ? 10 : 2,
                        itemWidth: tabValue === 1 ? 80 : 75,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ],
                        textColor: textColor,
                        itemTextColor: textColor,
                        onClick: handleLegendClick
                    }
                ] : [
                    {
                        data: customLegendItems,
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 25,
                        translateY: 85,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ],
                        textColor: textColor,
                        itemTextColor: textColor,
                        onClick: handleLegendClick
                    }
                ]}
                tooltip={customTooltip}
                motionConfig="slow"
                theme={{
                    textColor: textColor,
                    axis: {
                        legend: {
                            text: {
                                fill: textColor
                            }
                        },
                        ticks: {
                            text: {
                                fill: textColor
                            }
                        }
                    }
                }}
                defs={gradientDefs}
                fill={allBarNames.map((name, index) => ({
                    match: { id: name },
                    id: `gradient${index + 1}`,
                }))}
            />
        </div>
    );
};

export default HourlyBarChart;
