import React, { useState, useCallback } from 'react';
import YearlyHeader from './YearlyHeader';
import useSelectedSensor from '../../hooks/useSelectedSensor';
import useSensorOptions from '../../hooks/useSensorOptions';
import useCompanyOptions from '../../hooks/useCompanyOptions';
import useSelectedCompany from '../../hooks/useSelectedCompany';
import YearlyContent from './YearlyContent';
import { Box, Grid, Paper } from '@mui/material';
import { useYearlyDataQuery, useAdultnChildYearlyDataQuery } from '../../services/api';
import { useMediaQuery } from 'react-responsive';
import YearlyFilterBox from './YearlyFilterBox';
import YearlyLabel from './YearlyLabel';
import { useSelector } from 'react-redux';

const Yearly = () => {
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });

    const { company, role } = useSelector(state => state.global);

    const companyOptions = useCompanyOptions();

    const isSuperOrPowerAdmin = role === 'Super Admin' || role === 'Power Admin';

    const [showLabels, setShowLabels] = useState(false); 
    const [tabValue, setTabValue] = useState(0);

    const { selectedCompany, handleCompanyChange } = useSelectedCompany({ companyOptions });
    const sensorOptions = useSensorOptions(isSuperOrPowerAdmin ? selectedCompany : company);
    const { selectedSensor, handleSensorChange, getSelectedSensorLabel } = useSelectedSensor(sensorOptions, isSuperOrPowerAdmin ? selectedCompany : company);

    const { data: yearlyData, isLoading: yearlyLoading, error: yearlyError } = useYearlyDataQuery(
        { mac: selectedSensor },
        { skip: tabValue !== 0 }
    );
    const { data: adultChildData, isLoading: adultChildLoading, error: adultChildError } = useAdultnChildYearlyDataQuery(
        { mac: selectedSensor },
        { skip: tabValue !== 1 }
    );

    // Determine which data to use based on the selected tab
    const processedData = tabValue === 0 ? yearlyData : adultChildData;
    const chartIsLoading = tabValue === 0 ? yearlyLoading : adultChildLoading;
    const error = tabValue === 0 ? yearlyError : adultChildError;

    const handleShowLabelsChange = useCallback((event) => {
        setShowLabels(event.target.checked);
    }, []);

    const handleTabChange = (newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box m={isSmallScreen ? "0.5rem" : "1rem 2.5rem"}>
            <Grid container spacing={2} paddingBottom={3}>
                <Grid item xs={12} md={10}>
                    <YearlyHeader isSmallScreen={isSmallScreen} />
                    <YearlyLabel
                        sensorLabel={getSelectedSensorLabel()}
                        isSmallScreen={isSmallScreen}
                        tabValue={tabValue}
                        onTabChange={handleTabChange}
                    />
                    <YearlyContent
                        selectedSensor={selectedSensor}
                        showLabels={showLabels}
                        processedData={processedData}
                        chartIsLoading={chartIsLoading}
                        error={error}
                        isSmallScreen={isSmallScreen}
                        sensorLabel={getSelectedSensorLabel()}
                        tabValue={tabValue}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Paper sx={{ position: 'sticky', top: 20, height: 'auto', overflow: 'auto', borderRadius: '15px' }}>
                        <YearlyFilterBox
                            sensorLabel={getSelectedSensorLabel()}
                            isSmallScreen={isSmallScreen}
                            showLabels={showLabels}
                            onShowLabelsChange={handleShowLabelsChange}
                            sensorOptions={sensorOptions}
                            selectedSensor={selectedSensor}
                            handleSensorChange={handleSensorChange}
                            companyOptions={companyOptions}
                            selectedCompany={isSuperOrPowerAdmin ? selectedCompany : null}
                            handleCompanyChange={isSuperOrPowerAdmin ? handleCompanyChange : null}
                            isSuperAdmin={isSuperOrPowerAdmin}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Yearly;
