import React, { useState, useEffect, useCallback } from 'react';
import DailyHeader from './DailyHeader';
import useSelectedSensor from '../../hooks/useSelectedSensor';
import useSensorOptions from '../../hooks/useSensorOptions';
import useCompanyOptions from '../../hooks/useCompanyOptions';
import useSelectedCompany from '../../hooks/useSelectedCompany';
import DailyContent from './DailyContent';
import { Box, Grid, Paper } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedDevice } from '../../services/state/globalSlice';
import { useMediaQuery } from 'react-responsive';
import { useDailyDataQuery, useAdultnChildDailyDataQuery } from '../../services/api';
import DailyFilterBox from './DailyFilterBox';
import DailyLabel from './DailyLabel';

const Daily = () => {
    const dispatch = useDispatch();
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });

    // Redux state
    const { company, role } = useSelector((state) => state.global);

    const companyOptions = useCompanyOptions();

    const isSuperOrPowerAdmin = role === 'Super Admin' || role === 'Power Admin';

    // State variables
    const [initialLoad, setInitialLoad] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
    const [showLabels, setShowLabels] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    const { selectedCompany, handleCompanyChange } = useSelectedCompany({ companyOptions });
    const sensorOptions = useSensorOptions(isSuperOrPowerAdmin ? selectedCompany : company);
    const { selectedSensor, handleSensorChange, getSelectedSensorLabel } = useSelectedSensor(sensorOptions, isSuperOrPowerAdmin ? selectedCompany : company);

    // Call both queries unconditionally
    const { data: dailyData, isLoading: dailyLoading, error: dailyError } = useDailyDataQuery(
        { mac: selectedSensor },
        { skip: tabValue !== 0 }
    );
    const { data: adultChildData, isLoading: adultChildLoading, error: adultChildError } = useAdultnChildDailyDataQuery(
        { mac: selectedSensor },
        { skip: tabValue !== 1 }
    );

    // Determine which data to use based on the selected tab
    const processedData = tabValue === 0 ? dailyData : adultChildData;
    const chartIsLoading = tabValue === 0 ? dailyLoading : adultChildLoading;
    const error = tabValue === 0 ? dailyError : adultChildError;

    // Memoized callbacks
    const handlePopoverOpen = useCallback((event) => setAnchorEl(event.currentTarget), []);
    const handlePopoverClose = useCallback(() => setAnchorEl(null), []);

    const handleShowLabelsChange = useCallback((event) => {
        setShowLabels(event.target.checked);
    }, []);

    const handleTabChange = (newValue) => {
        setTabValue(newValue);
    };

    // Initial load effect
    useEffect(() => {
        if (initialLoad && sensorOptions.length > 0) {
            if (!selectedSensor) {
                const randomIndex = Math.floor(Math.random() * sensorOptions.length);
                const initialSelectedDevice = sensorOptions[randomIndex].mac;
                dispatch(setSelectedDevice(initialSelectedDevice));
            }
            const endDate = new Date();
            const startDate = new Date(endDate);
            startDate.setDate(startDate.getDate() - 10);
            startDate.setHours(0, 0, 0, 0);
            setSelectedDateRange([{ startDate, endDate, key: 'selection' }]);
            setInitialLoad(false);
        }
    }, [initialLoad, sensorOptions, dispatch, selectedSensor]);

    // JSX
    return (
        <Box m={isSmallScreen ? "0.5rem" : "1rem 2.5rem"}>
            <Grid container spacing={2} paddingBottom={3}>
                <Grid item xs={12} md={10}>
                    <DailyHeader isSmallScreen={isSmallScreen} />
                    <DailyLabel
                        sensorLabel={getSelectedSensorLabel()}
                        isSmallScreen={isSmallScreen}
                        tabValue={tabValue}
                        onTabChange={handleTabChange}
                    />
                    <DailyContent
                        selectedSensor={selectedSensor}
                        showLabels={showLabels}
                        processedData={processedData}
                        chartIsLoading={chartIsLoading}
                        error={error}
                        isSmallScreen={isSmallScreen}
                        sensorLabel={getSelectedSensorLabel()}
                        selectedDateRange={selectedDateRange}
                        tabValue={tabValue}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Paper sx={{ position: 'sticky', top: 20, height: 'auto', overflow: 'auto', borderRadius: '15px' }}>
                        <DailyFilterBox
                            sensorLabel={getSelectedSensorLabel()}
                            isSmallScreen={isSmallScreen}
                            showLabels={showLabels}
                            onShowLabelsChange={handleShowLabelsChange}
                            sensorOptions={sensorOptions}
                            selectedSensor={selectedSensor}
                            selectedDateRange={selectedDateRange}
                            setSelectedDateRange={setSelectedDateRange}
                            handleSensorChange={handleSensorChange}
                            handlePopoverOpen={handlePopoverOpen}
                            handlePopoverClose={handlePopoverClose}
                            anchorEl={anchorEl}
                            companyOptions={companyOptions}
                            selectedCompany={isSuperOrPowerAdmin ? selectedCompany : null}
                            handleCompanyChange={isSuperOrPowerAdmin ? handleCompanyChange : null}
                            isSuperAdmin={isSuperOrPowerAdmin}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Daily;
