import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

const CompanyDetails = ({ companyData, selectedCompany }) => {
    const theme = useTheme();

    // Find the selected company based on the name
    const selectedCompanyData = companyData.find(company => company.name === selectedCompany);

    return (
        <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.paper}
            borderRadius="0.55rem"
            position="relative"
            overflow="auto"
        >
            <Typography variant="h3" sx={{ color: theme.palette.secondary.main, m: "4%", fontWeight: 'bold' }}>
                COMPANY DETAILS
            </Typography>
            {selectedCompanyData && (
                <Box sx={{ m: "4%" }}>
                    <Typography variant="h4" gutterBottom>
                        <strong>Address:</strong> {selectedCompanyData.address}
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        <strong>City:</strong> {selectedCompanyData.city}
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        <strong>Country:</strong> {selectedCompanyData.country}
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        <strong>Industry:</strong> {selectedCompanyData.industry}
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        <strong>Contact Email:</strong> {selectedCompanyData.contactEmail}
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        <strong>Contact Phone:</strong> {selectedCompanyData.contactPhone}
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        <strong>Website:</strong> <a href={selectedCompanyData.website} target="_blank" rel="noopener noreferrer">{selectedCompanyData.website}</a>
                    </Typography>
                </Box>
            )}
        </Box>
    );
}

export default CompanyDetails;