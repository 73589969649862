import React from 'react';
import { Box, Button, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import mainLocationsInQatar from '../../constants/mainLocations';

const SiteDetailsForm = ({ site, siteDetails, handleInputChange, handleSiteSelect, handleUpdateSiteDetails, filteredSites, isSmallScreen }) => {
    const theme = useTheme();

    return (
        <Box
            p={4}
            borderRadius={5}
            sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.secondary.main,
                width: '50%',
                mx: 'auto',
                mt: 2
            }}
        >
            <Typography variant="h3" sx={{ fontSize: '2rem', fontWeight: 'bold', color: theme.palette.secondary.main, mb: '1rem' }}>
                Site Details
            </Typography>
            <Box mb={2}>
                <Typography>Select Site</Typography>
                <Select
                    fullWidth
                    value={site}
                    onChange={handleSiteSelect}
                    displayEmpty
                >
                    <MenuItem value="" disabled>
                        Select Site
                    </MenuItem>
                    {filteredSites.map((site) => (
                        <MenuItem key={site._id} value={site.name}>
                            {site.name}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
            <Box mb={2}>
                <Typography>Change Site Name</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={siteDetails.name}
                    onChange={(e) => handleInputChange(e, 'name')}
                    disabled
                />
            </Box>
            <Box mb={2}>
                <Typography>Location</Typography>
                <Select
                    fullWidth
                    value={siteDetails.location}
                    onChange={(e) => handleInputChange(e, 'location')}
                >
                    {mainLocationsInQatar && mainLocationsInQatar.map((location) => (
                        <MenuItem key={location.name} value={location.name}>
                            {location.name}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
            <Box mb={2}>
                <Typography>Latitude</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={siteDetails.latitude}
                    onChange={(e) => handleInputChange(e, 'latitude')}
                />
            </Box>
            <Box mb={2}>
                <Typography>Longitude</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={siteDetails.longitude}
                    onChange={(e) => handleInputChange(e, 'longitude')}
                />
            </Box>
            <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                gap={3}
                mt={2}
            >
                <Button
                    onClick={handleUpdateSiteDetails}
                    variant="contained"
                    sx={{
                        mt: '10px',
                        color: theme.palette.text.black,
                        backgroundColor: theme.palette.primary.main,
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: theme.palette.secondary.dark,
                        },
                        '&:focus': {
                            outline: 'none',
                        }
                    }}
                >
                    Update Site Details
                </Button>
            </Box>
        </Box>
    );
};

export default SiteDetailsForm;
