import React, { useState, useCallback } from 'react';
import MonthlyHeader from './MonthlyHeader';
import useSelectedSensor from '../../hooks/useSelectedSensor';
import useSensorOptions from '../../hooks/useSensorOptions';
import useCompanyOptions from '../../hooks/useCompanyOptions';
import useSelectedCompany from '../../hooks/useSelectedCompany';
import MonthlyContent from './MonthlyContent';
import { Box, Grid, Paper } from '@mui/material';
import { useMonthlyDataQuery, useAdultnChildMonthlyDataQuery } from '../../services/api';
import { useMediaQuery } from 'react-responsive';
import MonthlyFilterBox from './MonthlyFilterBox';
import MonthlyLabel from './MonthlyLabel';
import { useSelector } from 'react-redux';

const Monthly = () => {
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });

    const { company, role } = useSelector(state => state.global);

    const companyOptions = useCompanyOptions();

    const isSuperOrPowerAdmin = role === 'Super Admin' || role === 'Power Admin';

    // State variables
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedYearRange, setSelectedYearRange] = useState(getDefaultYearRange());
    const [showLabels, setShowLabels] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    // Custom hooks
    const { selectedCompany, handleCompanyChange } = useSelectedCompany({ companyOptions });
    const sensorOptions = useSensorOptions(isSuperOrPowerAdmin ? selectedCompany : company);
    const { selectedSensor, handleSensorChange, getSelectedSensorLabel } = useSelectedSensor(sensorOptions, isSuperOrPowerAdmin ? selectedCompany : company);

    const { data: monthlyData, isLoading: monthlyLoading, error: monthlyError } = useMonthlyDataQuery(
        { mac: selectedSensor },
        { skip: tabValue !== 0 }
    );
    const { data: adultChildData, isLoading: adultChildLoading, error: adultChildError } = useAdultnChildMonthlyDataQuery(
        { mac: selectedSensor },
        { skip: tabValue !== 1 }
    );

    // Determine which data to use based on the selected tab
    const processedData = tabValue === 0 ? monthlyData : adultChildData;
    const chartIsLoading = tabValue === 0 ? monthlyLoading : adultChildLoading;
    const error = tabValue === 0 ? monthlyError : adultChildError;

    const handlePopoverOpen = useCallback((event) => setAnchorEl(event.currentTarget), []);
    const handlePopoverClose = useCallback(() => setAnchorEl(null), []);

    // Helper function to get default year range
    function getDefaultYearRange() {
        const currentYear = new Date().getFullYear();
        return [currentYear];
    }

    const handleShowLabelsChange = useCallback((event) => {
        setShowLabels(event.target.checked);
    }, []);

    const handleTabChange = (newValue) => {
        setTabValue(newValue);
    };

    // JSX
    return (
        <Box m={isSmallScreen ? "0.5rem" : "1rem 2.5rem"}>
            <Grid container spacing={2} paddingBottom={3}>
                <Grid item xs={12} md={10}>
                    <MonthlyHeader isSmallScreen={isSmallScreen} />
                    <MonthlyLabel
                        sensorLabel={getSelectedSensorLabel()}
                        isSmallScreen={isSmallScreen}
                        tabValue={tabValue}
                        onTabChange={handleTabChange}
                    />
                    <MonthlyContent
                        selectedSensor={selectedSensor}
                        selectedYearRange={selectedYearRange}
                        showLabels={showLabels}
                        processedData={processedData}
                        chartIsLoading={chartIsLoading}
                        error={error}
                        isSmallScreen={isSmallScreen}
                        sensorLabel={getSelectedSensorLabel()}
                        tabValue={tabValue}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Paper sx={{ position: 'sticky', top: 20, height: 'auto', overflow: 'auto', borderRadius: '15px' }}>
                        <MonthlyFilterBox
                            sensorLabel={getSelectedSensorLabel()}
                            isSmallScreen={isSmallScreen}
                            showLabels={showLabels}
                            onShowLabelsChange={handleShowLabelsChange}
                            sensorOptions={sensorOptions}
                            selectedSensor={selectedSensor}
                            selectedYearRange={selectedYearRange}
                            setSelectedYearRange={setSelectedYearRange}
                            handleSensorChange={handleSensorChange}
                            handlePopoverOpen={handlePopoverOpen}
                            handlePopoverClose={handlePopoverClose}
                            anchorEl={anchorEl}
                            companyOptions={companyOptions}
                            selectedCompany={isSuperOrPowerAdmin ? selectedCompany : null}
                            handleCompanyChange={isSuperOrPowerAdmin ? handleCompanyChange : null}
                            isSuperAdmin={isSuperOrPowerAdmin}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Monthly;
