import React, { useState, useMemo } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel } from '@mui/material';

const AllSitesTable = ({ sitesData = [], selectedCompany}) => {

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(property);
    };

    const filteredSitesData = useMemo(() => {
        if (!Array.isArray(sitesData)) {
            return [];
        }

        if (!selectedCompany) {
            return sitesData;
        } else {
            return sitesData.filter(site => site.company === selectedCompany);
        }
    }, [sitesData, selectedCompany]);

    const sortedData = useMemo(() => {
        if (!Array.isArray(filteredSitesData)) {
            return [];
        }

        return [...filteredSitesData].sort((a, b) => {
            if (order === 'asc') {
                return a[orderBy] > b[orderBy] ? 1 : -1;
            } else {
                return b[orderBy] > a[orderBy] ? 1 : -1;
            }
        });
    }, [filteredSitesData, orderBy, order]);

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString();
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'name'}
                            direction={orderBy === 'name' ? order : 'asc'}
                            onClick={createSortHandler('name')}
                        >
                            Name
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'company'}
                            direction={orderBy === 'company' ? order : 'asc'}
                            onClick={createSortHandler('company')}
                        >
                            Company
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'location'}
                            direction={orderBy === 'location' ? order : 'asc'}
                            onClick={createSortHandler('location')}
                        >
                            Location
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'enabled'}
                            direction={orderBy === 'enabled' ? order : 'asc'}
                            onClick={createSortHandler('enabled')}
                        >
                            Enabled
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        Latitude
                    </TableCell>
                    <TableCell>
                        Longitude
                    </TableCell>
                    <TableCell>
                        Connected MACS
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'createdAt'}
                            direction={orderBy === 'createdAt' ? order : 'asc'}
                            onClick={createSortHandler('createdAt')}
                        >
                            Created Date
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'updatedAt'}
                            direction={orderBy === 'updatedAt' ? order : 'asc'}
                            onClick={createSortHandler('updatedAt')}
                        >
                            Updated Date
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {sortedData.map(site => (
                    <TableRow key={site._id}>
                        <TableCell>{site.name}</TableCell>
                        <TableCell>{site.company}</TableCell>
                        <TableCell>{site.location}</TableCell>
                        <TableCell>{site.enabled ? 'Yes' : 'No'}</TableCell>
                        <TableCell>{site.latitude}</TableCell>
                        <TableCell>{site.longitude}</TableCell>
                        <TableCell>{site.connectedMACs.join(', ')}</TableCell>
                        <TableCell>{formatDate(site.createdAt)}</TableCell>
                        <TableCell>{formatDate(site.updatedAt)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default AllSitesTable