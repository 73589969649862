import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const RenewLicenseDialogBox = ({ open, onClose, packagesData, selectedPackage, handlePackageChange, handleSave, showSnackbar }) => {
    const [selectedPackageState, setSelectedPackageState] = useState(selectedPackage);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedPackageState(newValue);
        handlePackageChange(event);
    };

    const handleSaveClick = async () => {
        try {
            await handleSave(selectedPackageState);
            showSnackbar('License updated successfully', 'success');
            onClose();
        } catch (error) {
            showSnackbar('Failed to update package', 'error');
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Edit Package</DialogTitle>
            <DialogContent>
                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel id="package-label">Package</InputLabel>
                    <Select
                        labelId="package-label"
                        id="package-select"
                        value={selectedPackageState || ''}
                        onChange={handleChange}
                        label="Package"
                    >
                        {packagesData?.map((pkg) => (
                            <MenuItem key={pkg._id} value={pkg._id}>
                                {pkg.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Cancel</Button>
                <Button onClick={handleSaveClick} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default RenewLicenseDialogBox;
