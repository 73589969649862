import React from "react";
import styles from "./styles.module.css";
import useLoginHook from "../../hooks/useLoginHook.js";
import backgroundIMG from "../../assets/background.jpeg"

const Login = () => {
  const { error, handleChange, handleSubmit, inputs } = useLoginHook();

  // JSX rendering
  return (
    <div className={styles.login_container} style={{ backgroundImage: `url(${backgroundIMG})` }}>
      <div className={styles.login_form_container}>
        <div className={styles.left}>
          <form className={styles.form_container} onSubmit={handleSubmit}>
            <h1>LOGIN</h1>
            <input
              type="email"
              placeholder="Email"
              name="email"
              onChange={handleChange}
              value={inputs.email}
              required
              className={styles.input}
            />
            <input
              type="password"
              placeholder="Password"
              name="password"
              onChange={handleChange}
              value={inputs.password}
              required
              className={styles.input}
            />
            {error && <div className={styles.error_msg}>{error}</div>}
            <button type="submit" className={styles.green_btn}>
              Sign In
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;