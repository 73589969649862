import { linearGradientDef } from '@nivo/core';

export const customColorScheme = ['#28A3FE', '#8158F6', '#FC5D2A', '#BD1313'];

export const gradientDefs = [
    linearGradientDef('gradient1', [
        { offset: 0, color: '#28CBFE' },
        { offset: 100, color: '#2061cb' }
    ]),
    linearGradientDef('gradient2', [
        { offset: 0, color:  '#a46af7'},
        { offset: 100, color: '#2e227a' }
    ]),
    linearGradientDef('gradient3', [
        { offset: 0, color: '#FC8F2A' },
        { offset: 100, color: '#FB2A29' }
    ]),
    linearGradientDef('gradient4', [
        { offset: 0, color: '#fc1919' },
        { offset: 100, color: '#7e0c0c' }
    ]),
];