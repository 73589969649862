import React, { useState, useCallback, useEffect } from 'react';
import ReportHeader from './ReportHeader';
import SensorSelect from '../../components/SensorSelect';
import CompanySelector from '../../components/CompanySelector';
import MonthPopover from './MonthPopover';
import GenerateReport from './GenerateReport';
import useSelectedSensor from '../../hooks/useSelectedSensor';
import useSensorOptions from '../../hooks/useSensorOptions';
import useCompanyOptions from '../../hooks/useCompanyOptions';
import useSelectedCompany from '../../hooks/useSelectedCompany';
import ReportContent from './ReportContent';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import {
    useExtremeDataQuery,
    useMonthlyReportDataQuery,
    useAdultnChildExtremeDataQuery,
    useAdultnChildMonthlyReportDataQuery,
    useUpdateSheetMutation,
    useAdultnChildUpdateSheetMutation,
    useGetSitesQuery
} from '../../services/api';
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';
import ReportLabel from './ReportLable';

const Report = () => {
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });

    const { company, role } = useSelector((state) => state.global);

    const companyOptions = useCompanyOptions();

    const isSuperOrPowerAdmin = role === 'Super Admin' || role === 'Power Admin';

    // State variables
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(dayjs().month() + 1);
    const [selectedYear, setSelectedYear] = useState(dayjs().year());
    const [tempSelectedMonth, setTempSelectedMonth] = useState(null);
    const [tempSelectedYear, setTempSelectedYear] = useState(null);
    const [showPdfButton, setShowPdfButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [connectedDevices, setConnectedDevices] = useState("");
    const [pdfGenerated, setPdfGenerated] = useState(false);
    const [updateTotalSheet] = useUpdateSheetMutation();
    const [updateAdultnChildSheet] = useAdultnChildUpdateSheetMutation();
    const [tabValue, setTabValue] = useState(0);

    // Redux state
    const { data: sitesData } = useGetSitesQuery(company);

    // Custom hooks
    const { selectedCompany, handleCompanyChange } = useSelectedCompany({ companyOptions });
    const sensorOptions = useSensorOptions(isSuperOrPowerAdmin ? selectedCompany : company);
    const { selectedSensor, handleSensorChange, getSelectedSensorLabel } = useSelectedSensor(sensorOptions, isSuperOrPowerAdmin ? selectedCompany : company);

    const handlePopoverOpen = useCallback((event) => setAnchorEl(event.currentTarget), []);
    const handlePopoverClose = useCallback(() => setAnchorEl(null), []);
    const handleDateChange = useCallback((date) => {
        setTempSelectedMonth(date.month() + 1);
        setTempSelectedYear(date.year());
    }, []);

    const handleApply = useCallback(() => {
        setSelectedMonth(tempSelectedMonth);
        setSelectedYear(tempSelectedYear);
        handlePopoverClose();
    }, [tempSelectedMonth, tempSelectedYear, handlePopoverClose]);

    const handleTabChange = (newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        if (selectedSensor && sitesData && sitesData.length > 0) {
            const siteWithSensor = sitesData.find(site => site.connectedMACs.includes(selectedSensor));
            if (siteWithSensor) {
                setSelectedLocation(siteWithSensor.location);
                setConnectedDevices(siteWithSensor.devices);
            } else {
                setSelectedLocation("");
            }
        }
    }, [selectedSensor, sitesData]);

    const handlePdfButtonClick = async () => {
        setLoading(true);
        await sendDataToUpdateSheet();

        setTimeout(() => {
            setShowPdfButton(true);
            setLoading(false);
            setPdfGenerated(true);
        }, 2500);
    };

    const handleDownloadPdfButton = () => {
        const pdfUrl = tabValue === 0 
            ? 'https://docs.google.com/spreadsheets/d/1o1gmEEKvQ6AqmopYkzb4wfZeeg_4HPK0QGihIx4DfAM/export?format=pdf&size=A4&top_margin=0.00&bottom_margin=0.00&left_margin=0.00&right_margin=0.00&gridlines=false'
            : 'https://docs.google.com/spreadsheets/d/1jSJLIlFm5PKEWIBKtb0Kk1YPLnl84UIBzHtI9Hl3f8Y/export?format=pdf&size=A4&top_margin=0.00&bottom_margin=0.00&left_margin=0.00&right_margin=0.00&gridlines=false';

        const anchor = document.createElement('a');
        anchor.href = pdfUrl;
        anchor.download = 'report.pdf';
        anchor.click();
        setShowPdfButton(false);
        setPdfGenerated(false);
    };

    const sendDataToUpdateSheet = async () => {
        const deviceData = monthlyData['monthlyData'][selectedSensor];
        const yearData = deviceData[selectedYear];
        const monthData = yearData[selectedMonth.toString().padStart(2, '0')];
        let totalCount = 0;

        const totalMonth = monthData ? monthData.Total : 0;

        for (const year in deviceData) {
            if (parseInt(year) > selectedYear) break;

            const yearData = deviceData[year];

            for (const month in yearData) {
                if (parseInt(year) === selectedYear && parseInt(month) > selectedMonth) break;

                const monthData = yearData[month];
                const totalMonth = monthData ? monthData.Total : 0;
                totalCount += totalMonth;
            }
        }

        switch (selectedSensor) {
            case '00:6E:02:02:5E:14':
                totalCount -= 16008;
                break;
            case '00:6E:02:02:7F:9C':
                totalCount -= 34169;
                break;
            case '00:6E:02:02:7D:C0':
                totalCount += 7106;
                break;
            case '00:6E:02:02:5E:10':
                totalCount -= 97;
                break;
            case '00:6E:02:02:7F:A4':
                totalCount += 4812;
                break;
            case '00:6E:02:02:7D:80':
                totalCount += 180;
                break;
            default:
                break;
        }

        const updateData = {
            Device: getSelectedSensorLabel(),
            SelectedDevice: selectedSensor,
            Location: selectedLocation,
            ConnectedDevices: connectedDevices,
            TotalMonth: totalMonth.toString(),
            TotalCount: totalCount.toString(),
            SelectedMonth: selectedMonth.toString().padStart(2, '0'),
            SelectedYear: selectedYear
        };

        try {
            if (tabValue === 0) {
                await updateTotalSheet(updateData);
            } else if (tabValue === 1) {
                await updateAdultnChildSheet(updateData);
            }
        } catch (error) {
            console.error('Error updating sheet:', error);
        }
    };
    
    const { data: extremeData, isLoading: extremeDataLoading } = useExtremeDataQuery(
        { mac: selectedSensor, month: selectedMonth.toString().padStart(2, '0'), year: selectedYear },
        { skip: tabValue !== 0 }
    );

    const { data: monthlyData, isLoading: monthlyDataLoading } = useMonthlyReportDataQuery(
        { mac: selectedSensor, month: selectedMonth.toString().padStart(2, '0'), year: selectedYear },
        { skip: tabValue !== 0 }
    );

    const { data: adultChildExtremeData, isLoading: adultChildExtremeDataLoading } = useAdultnChildExtremeDataQuery(
        { mac: selectedSensor, month: selectedMonth.toString().padStart(2, '0'), year: selectedYear },
        { skip: tabValue !== 1 }
    );
    
    const { data: adultChildWeeklynMonthlyData, isLoading: adultChildWeeklynMonthlyDataLoading } = useAdultnChildMonthlyReportDataQuery(
        { mac: selectedSensor, month: selectedMonth.toString().padStart(2, '0'), year: selectedYear },
        { skip: tabValue !== 1 }
    );

    // Determine which data to use based on the selected tab
    const processedData = tabValue === 0 ? extremeData : adultChildExtremeData;
    const chartIsLoading = tabValue === 0 ? extremeDataLoading : adultChildExtremeDataLoading;

    const monthlyProcessedData = tabValue === 0 ? monthlyData : adultChildWeeklynMonthlyData;
    const monthlyDataIsLoading = tabValue === 0 ? monthlyDataLoading : adultChildWeeklynMonthlyDataLoading;

    return (
        <Box m={isSmallScreen ? "0.5rem" : "1rem 2.5rem"}>
            <ReportHeader isSmallScreen={isSmallScreen}>
                 {isSuperOrPowerAdmin && (
                    <CompanySelector selectedCompany={selectedCompany} handleCompanyChange={handleCompanyChange} companyOptions={companyOptions} />
                )}
                <SensorSelect sensorOptions={sensorOptions} selectedSensor={selectedSensor} handleSensorChange={handleSensorChange} isSmallScreen={isSmallScreen} />
                <MonthPopover
                    anchorEl={anchorEl}
                    handlePopoverOpen={handlePopoverOpen}
                    handlePopoverClose={handlePopoverClose}
                    handleDateChange={handleDateChange}
                    handleApply={handleApply}
                    isSmallScreen={isSmallScreen}
                    tempSelectedMonth={tempSelectedMonth}
                    tempSelectedYear={tempSelectedYear}
                />
                <GenerateReport
                    loading={loading}
                    showPdfButton={showPdfButton}
                    pdfGenerated={pdfGenerated}
                    handleDownloadPdfButton={handleDownloadPdfButton}
                    handlePdfButtonClick={handlePdfButtonClick}
                />
            </ReportHeader>
            <ReportLabel
                sensorLabel={getSelectedSensorLabel()}
                isSmallScreen={isSmallScreen}
                tabValue={tabValue}
                onTabChange={handleTabChange}
            />
            <ReportContent
                selectedSensor={selectedSensor}
                isSmallScreen={isSmallScreen}
                extremeData={processedData}
                extremeDataLoading={chartIsLoading}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                monthlyProcessedData={monthlyProcessedData}
                monthlyDataIsLoading={monthlyDataIsLoading}
                sensorLabel={getSelectedSensorLabel()}
            />
        </Box>
    );
};

export default Report;
