import React, { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { useSelector } from 'react-redux';
import { customTooltip } from '../components/customTooltip';
import { gradientDefs } from '../components/gradientDefs';
import { useTheme } from '@mui/material';

const YearlyBarChart = ({ isLoading, selectedMac, showLabels, isSmallScreen, processedData, chartIsLoading, error, tabValue }) => {
    const themeMode = useSelector(({ global }) => global.mode);
    const [activeLegends, setActiveLegends] = useState([]);
    const theme = useTheme();
    const [internalLoading, setInternalLoading] = useState(true);

    useEffect(() => {
        if (selectedMac) {
            setInternalLoading(true);
            // Simulate data fetching delay
            setTimeout(() => {
                setInternalLoading(false);
            }, 500); // Adjust the timeout duration as needed
        }
    }, [selectedMac]);

    if (isLoading || chartIsLoading || internalLoading) {
        return (
            <div style={{ height: '70vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loading />
            </div>
        );
    }

    // Check for errors
    if (error) {
        return <NoDataAvailableMessage />;
    }

    // Check if data is available
    if (!processedData || Object.keys(processedData).length === 0) {
        return <NoDataAvailableMessage />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const filteredData = selectedMac ? (processedData && processedData[selectedMac]) || {} : processedData || {};

    const lastFiveYears = Array.from({ length: 5 }, (_, index) => {
        const currentYear = new Date().getFullYear();
        return (currentYear - index).toString();
    });

    const firstYearData = filteredData[lastFiveYears[0]] || {};
    const keys = firstYearData ? Object.keys(firstYearData) : [];

    const chartData = lastFiveYears.reverse().map(year => {
        const yearData = filteredData[year] || {};
        const yearValues = { year };

        keys.forEach(category => {
            yearValues[category] = yearData[category] || 0;
        });

        return yearValues;
    });

    const customLegendItems = keys.map((category, index) => ({
        id: category,
        label: category,
        color: `url(#gradient${index + 1})`,
    }));

    const handleLegendClick = (legendItem) => {
        setActiveLegends((prevActiveLegends) =>
            prevActiveLegends.includes(legendItem.id)
                ? prevActiveLegends.filter((item) => item !== legendItem.id)
                : [...prevActiveLegends, legendItem.id]
        );
    };

    const filteredKeys = keys.filter((name) => !activeLegends.includes(name));

    return (
        <div style={{
            height: '70vh',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '15px'
        }}>
            <ResponsiveBar
                data={chartData}
                keys={filteredKeys}
                indexBy="year"
                margin={isSmallScreen ? { top: 30, right: 10, bottom: 90, left: 40 } : { top: 30, right: 30, bottom: 100, left: 50 }}
                padding={0.25}
                groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Year',
                    legendPosition: 'middle',
                    legendOffset: 45,
                    tickValues: 5,
                    style: { tick: { fill: '#ffffff' }, legend: { fill: '#ffffff' } }
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: 'middle',
                    legendOffset: -40,
                    tickValues: 5,
                    style: { tick: { fill: '#ffffff' }, legend: { fill: '#ffffff' } }
                }}
                enableLabel={showLabels}
                labelSkipWidth={1}
                labelSkipHeight={1}
                labelTextColor={textColor}
                legends={isSmallScreen ? [
                    {
                        data: customLegendItems,
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: tabValue === 1 ? -10 : 10,
                        translateY: 80,
                        itemsSpacing: tabValue === 1 ? 10 : 2,
                        itemWidth: tabValue === 1 ? 80 : 75,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ],
                        textColor: textColor,
                        itemTextColor: textColor,
                        onClick: handleLegendClick
                    }
                ] : [
                    {
                        data: customLegendItems,
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 25,
                        translateY: 85,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ],
                        textColor: textColor,
                        itemTextColor: textColor,
                        onClick: handleLegendClick
                    }
                ]}
                tooltip={customTooltip}
                motionConfig="slow"
                theme={{
                    tooltip: {
                        container: {
                            background: '#000',
                            color: '#fff',
                            fontSize: '14px',
                            borderRadius: '4px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
                            padding: '10px'
                        }
                    },
                    textColor: textColor,
                    axis: {
                        legend: {
                            text: {
                                fill: textColor
                            }
                        },
                        ticks: {
                            text: {
                                fill: textColor
                            }
                        }
                    }
                }}
                defs={gradientDefs}
                fill={keys.map((name, index) => ({
                    match: { id: name },
                    id: `gradient${index + 1}`,
                }))}
            />
        </div>
    );
};

export default YearlyBarChart;
